import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Loader } from "../../Utils/Loader";
import { GetFrontFaqPaginate } from "../../Services/Api/comman";

const FrontFaq = ({ pageNew, limitNew, orderByNew, orderNew }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [faqs, setFaqs] = useState([]);
  const page = pageNew;
  const limit = limitNew;
  const orderBy = orderByNew;
  const order = orderNew;

  const getFrontFaqPaginate = async (
    limit = "",
    page = "",
    orderBy = "",
    order = ""
  ) => {
    setIsLoading(true);
    await GetFrontFaqPaginate({
      limit: limit,
      page: page,
      orderBy: orderBy,
      order: order,
    })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setFaqs(res?.data?.data?.data);
        } else {
          setFaqs([]);
        }
      })
      .catch((err) => {
        setFaqs([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getFrontFaqPaginate(limit, page, orderBy, order);
  }, [limit, page, orderBy, order]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return !isLoading ? (
    <>
      {faqs?.map((faq, index) => (
        <Accordion defaultActiveKey={0} key={index} className="mb-3">
          <Accordion.Item eventKey={index}>
            <Accordion.Header>{faq?.question}</Accordion.Header>
            <Accordion.Body>
              <div dangerouslySetInnerHTML={{ __html: faq?.answer }}></div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}
    </>
  ) : (
    <Loader loading={isLoading} />
  );
};

export default FrontFaq;
