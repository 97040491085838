import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import FrontFaq from "./FrontFaq";

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>FAQ's | PhrasePulse</title>
        <meta
          name="description"
          content="PhrasePulse.com is an online platform designed to help users improve their language skills through interactive dialogue practice sessions."
        />
        <link rel="canonical" href="https://phrasepulse.com/faq" />
      </Helmet>
      <div className="inner-page">
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12} className="mb-4">
              <div className="common-page text-center">
                <h1>Frequently Asked Questions</h1>
              </div>
            </Col>
            <Col lg={12} md={12} sm={12} className="faq-sec p-0">
              <div className="card">
                <div className="card-body">
                  <FrontFaq
                    pageNew={0}
                    limitNew={20}
                    orderByNew={`createdOn`}
                    orderNew={`asc`}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FAQ;
