import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { UserChangePasswordService } from "../../../Services/Api/auth";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";

const ChangePassword = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const signInSchema = Yup.object().shape({
    current_password: Yup.string().required("Old Password is required"),
    password: Yup.string()
      .required("Password is required")
      .max(40, "Password can contain a maximum of 40 characters")
      .test(
        "uppderCase",
        "Must contain at least one uppercase character",
        (value) => /[A-Z]/.test(value)
      )
      .test(
        "lowerCase",
        "Must contain at least one lowercase character",
        (value) => /[a-z]/.test(value)
      )
      .test("oneNumber", "Must contain at least one number", (value) =>
        /[0-9]/.test(value)
      )
      .test(
        "oneSpecial",
        "Must contain at least one special character",
        (value) => /[!@#%&]/.test(value)
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  useEffect(() => {}, []);

  const initialValues = {
    current_password: "",
    password: "",
    confirm_password: "",
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Change Password</h4>
      </div>
      <div className="card-body">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={signInSchema}
          validateOnChange
          onSubmit={async (values, { setSubmitting }) => {
            const postData = {
              current_password: values.current_password,
              new_password: values.password,
              new_confirm_password: values.confirm_password,
            };
            await UserChangePasswordService(postData)
              .then((res) => {
                SuccessToast(
                  res?.data?.message || "Password Change successfully."
                );
                setSubmitting(false);
                window.location.reload(true);
              })
              .catch((err) => {
                if (typeof err.response.data.message !== "undefined") {
                  ErrorToast(err.response.data.message || "Server Error!!");
                } else {
                  ErrorToast(err?.message || "Server Error!!");
                }
                setSubmitting(false);
              });
          }}
        >
          {(formik) => {
            const { errors, touched, isSubmitting } = formik;
            return (
              <Form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Current Password <span className="text-danger">*</span>
                      </label>
                      <div className="inputWrap">
                        <Field
                          type={"text"}
                          className={`form-control ${
                            errors.current_password && touched.current_password
                              ? "input-error"
                              : null
                          }`}
                          name="current_password"
                          placeholder="Enter Current Password"
                        />
                      </div>
                      <ErrorMessage
                        name="current_password"
                        component="span"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="clearfix"></div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Password <span className="text-danger">*</span>
                      </label>
                      <div className="inputWrap">
                        <Field
                          type={isShowPassword ? "text" : "password"}
                          className={`form-control ${
                            errors.password && touched.password
                              ? "input-error"
                              : null
                          }`}
                          name="password"
                          placeholder="Enter password"
                        />
                        <span
                          onClick={() => setIsShowPassword(!isShowPassword)}
                          className={`fas ${
                            isShowPassword ? "fa-eye-slash" : "fa-eye"
                          } toggle-password-user`}
                        />
                      </div>
                      <ErrorMessage
                        name="password"
                        component="span"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Confirm Password <span className="text-danger">*</span>
                      </label>
                      <div className="inputWrap">
                        <Field
                          type={isShowConfirmPassword ? "text" : "password"}
                          className={`form-control ${
                            errors.confirm_password && touched.confirm_password
                              ? "input-error"
                              : null
                          }`}
                          name="confirm_password"
                          placeholder="Confirm password"
                        />
                        <span
                          onClick={() =>
                            setIsShowConfirmPassword(!isShowConfirmPassword)
                          }
                          className={`fas ${
                            isShowConfirmPassword ? "fa-eye-slash" : "fa-eye"
                          } toggle-password-user`}
                        />
                      </div>
                      <ErrorMessage
                        name="confirm_password"
                        component="span"
                        className="error"
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <button
                    type="submit"
                    className="btn btn-primary btnstl"
                    disabled={isSubmitting}
                  >
                    Change Password
                    {isSubmitting && <i className="fas fa-spinner fa-spin"></i>}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;
