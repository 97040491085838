import axios from "axios";
import Api from "../../Utils/Axios";
import UserApi from "../../Utils/UserAxios";

/************************ ADMIN API *****************************/

export const AdminLoginService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/login", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const getProfile = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/admin/get_profile", data));
    } catch (error) {
      reject(error);
    }
  });
};
export const updateProfile = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/auth/update_profile", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const AdminGetWalletBalanceService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get("/master/dashboard"));
    } catch (error) {
      reject(error);
    }
  });
};

export const ChangePasswordService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/admin/change_password", data));
    } catch (error) {
      reject(error);
    }
  });
};

/************************ USER API *****************************/

export const fetchIpAddress = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await axios.get("https://api.ipify.org?format=json"));
      // console.log('IP log', response.data.ip);
    } catch (error) {
      reject(error);
    }
  });
};

export const UserLoginService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/login", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const UserLogoutService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get("/logout", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const UserRegisterService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/register", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const UserUpdateProfileService = async (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post(`/user/update/${id}`, data));
    } catch (error) {
      reject(error);
    }
  });
};

export const UserChangePasswordService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/change-password", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const UserForgotPasswordService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/forgot-password", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const UserResetPasswordService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/reset", data));
    } catch (error) {
      reject(error);
    }
  });
};
