import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";
import { Loader } from "../../../Utils/Loader";
import { GetCategoryByLanguageIdUser } from "../../../Services/Api/category";
import {
  GetProductsByCategoryId,
  GetProductRendomByCategoryId,
  GetProductById,
} from "../../../Services/Api/product";
import {
  AddExerciseService,
  CheckExerciseService,
} from "../../../Services/Api/exercise";
import {
  clearProductData,
  clearCategoryData,
} from "../../../Services/Api/actions";
import SelectCategory from "./SelectCategory";
import { UserFooter } from "../Layout";
import StartTest from "./StartTest";
import Sections from "./Sections";

const Exercise = (props) => {
  const { userDetails, category, product } = props;
  const [initialValues, setInitialValues] = useState({
    language: userDetails?.user?.language_id || "",
    category: "",
    product: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [randomProduct, setRandomProduct] = useState(true);
  const [allCategory, setAllCategory] = useState({});
  const [allProductsData, setAllProductsData] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [exerciseId, setExerciseId] = useState("");
  const [sections, setSections] = useState([]);
  const [attemptSections, setAttemptSections] = useState([]);
  const [audioUrls, setAudioUrls] = useState([]);
  const [checkExercise, setCheckExercise] = useState(false);
  const [currentSection, setCurrentSection] = useState(0);

  const checkExerciseService = async () => {
    setIsLoading(true);
    await CheckExerciseService()
      .then((res) => {
        setIsLoading(false);
        let localExerciseData = res?.data?.data;
        if (localExerciseData?.continue_exercise) {
          ConfirmationRunningExercise(
            "Please finish your ongoing dialogues before starting new ones. Thank you!",
            localExerciseData?.exercise?._id
          )
            .then(() => {
              setCheckExercise(true);
              // console.log("checkExercise = ", localExerciseData?.continue_exercise);
              setExerciseId(localExerciseData?.exercise?._id);
              // product data
              fetchProductData(localExerciseData?.exercise?.product);
              // user attempt section data
              setAttemptSections(localExerciseData?.exercise?.sections);
              // update current section count
              if (
                localExerciseData?.exercise?.sections?.length ===
                localExerciseData?.exercise?.product?.sections
              ) {
                setCurrentSection(
                  localExerciseData?.exercise?.sections?.length
                );
              } else {
                setCurrentSection(
                  localExerciseData?.exercise?.sections?.length + 1
                );
              }
            })
            .catch((err) => {
              ErrorToast("Server Error!!");
            });
        } else {
          // console.log("product 1 = ", product);
          if (product) {
            getProductById(product);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log("product 2 = ", product);
        if (product) {
          getProductById(product);
        }
      });
  };

  const getProductById = useCallback(
    async (id) => {
      setIsLoading(true);
      await GetProductById(id)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.data) {
            // product data
            let productSections = res?.data?.data;
            fetchProductData(productSections);

            setInitialValues({
              ...initialValues,
              product: productSections?._id,
            });
            // update current section count
            setCurrentSection(1);
          } else {
            ErrorToast("Exercise Not Found!!");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setSelectedProduct({});
          setSections([]);
          setAudioUrls([]);
          setCurrentSection(0);
          if (typeof err?.response?.data?.message !== "undefined") {
            ErrorToast(err?.response?.data?.message || "Server Error!!");
          } else {
            ErrorToast(err?.message || "Server Error!!");
          }
        });
    },
    [initialValues]
  );

  const fetchProductData = (productSections) => {
    if (productSections) {
      // console.log("productSections = ", productSections);
      setSelectedProduct(productSections);
      if (productSections?.sections) {
        setSections(productSections?.sections);
        const localAudio = productSections?.sections?.map(
          (section) => section?.file_url
        );
        // console.log("localAudio = ", localAudio);
        setAudioUrls(localAudio);
      }
    }
  };

  const ConfirmationRunningExercise = (message, eId) =>
    Swal.fire({
      title: "Attention!!",
      titleText: "Attention!!",
      icon: "warning",
      text: message,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: "Continue",
      cancelButtonText: "Stop Practice",
      confirmButtonColor: "#28C76F",
      cancelButtonColor: "#FF0000",
      reverseButtons: true,
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        ConfirmationCancelExercise(eId);
      }
    });

  const ConfirmationCancelExercise = (eId) =>
    Swal.fire({
      title: "Stop Practice",
      titleText: "Stop Practice",
      icon: "warning",
      text: "Skipping practice loses PPC with no refunds. Thank you for understanding.",
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#28C76F",
      cancelButtonColor: "#FF0000",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        CancelExercise(eId);
      }
    });

  const handleStartExersice = async (id) => {
    setIsLoading(true);
    await AddExerciseService({ product_id: id })
      .then((res) => {
        setIsLoading(false);
        dispatch(clearProductData(null));
        if (res?.data?.data?.exercide_id) {
          setExerciseId(res?.data?.data?.exercide_id);
          SuccessToast("Exersice Start Now!!!");
          // console.log("exercide_id = ", res?.data?.data?.exercide_id);
        } else {
          if (res?.data?.data?.continue_exercise) {
            setExerciseId("");
            setCheckExercise(true);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
        setExerciseId("");
      });
  };

  const handleAddSectionExersice = async (data) => {
    let postData = {
      ...data,
      section: data?.section?.length ? data?.section[0] : {},
    };
    await AddExerciseService(postData).then((res) => {
      if (res?.data?.data?.exercide_id) {
        setExerciseId(res?.data?.data?.exercide_id);
        // console.log("U exercide_id = ", res?.data?.data?.exercide_id);
      } else {
        setExerciseId("");
      }
    });
  };

  const getCategoryByLanguageIdUser = async (id) => {
    setIsLoading(true);
    await GetCategoryByLanguageIdUser(id).then((res) => {
      setIsLoading(false);
      if (res?.data?.data?.length > 0) {
        let lanarr = res?.data?.data;
        lanarr = lanarr
          .map((value) => {
            if (
              !value.name.includes("Free") &&
              !value.name.includes("Feature")
            ) {
              return {
                label: value.name,
                value: value._id,
              };
            } else {
              return null;
            }
          })
          .filter((item) => item !== null);
        setAllCategory(lanarr);
      } else {
        setAllCategory({});
      }
    });
  };

  const getProductRendomByCategoryId = async (id) => {
    setIsLoading(true);
    await GetProductRendomByCategoryId(id)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data) {
          // product data
          let productSections = res?.data?.data;
          if (Object.keys(productSections)?.length > 0) {
            fetchProductData(productSections);

            setInitialValues({
              ...initialValues,
              product: productSections?._id,
            });
            // update current section count
            setCurrentSection(1);
          } else {
            ErrorToast("Dialogue not found!! Try to choose other level.");
          }
        } else {
          ErrorToast("Dialogue not found!!");
          setSelectedProduct({});
          setSections([]);
          setAudioUrls([]);
          setCurrentSection(0);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
        setSelectedProduct({});
        setSections([]);
        setAudioUrls([]);
        setCurrentSection(0);
      });
  };

  const getProductsByCategoryId = async (id) => {
    setIsLoading(true);
    await GetProductsByCategoryId(id)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.length > 0) {
          let localAllproducts = res?.data?.data;
          // console.log("localAllproducts = ", localAllproducts);
          setAllProductsData(localAllproducts);
        } else {
          setAllProductsData({});
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (typeof err?.response?.data?.message !== "undefined") {
          ErrorToast(err?.response?.data?.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
        setAllProductsData({});
      });
  };

  const CancelExercise = async (eId) => {
    const postData = {
      exercise: eId,
      isCancelled: true,
    };
    setIsLoading(true);
    await AddExerciseService(postData)
      .then((res) => {
        setIsLoading(false);
        // console.log("res = ", res);
        SuccessToast(res?.data?.message || "Exercise cancelled!!");
        navigate("/user/exercises");
      })
      .catch((err) => {
        setIsLoading(false);
        if (typeof err?.response?.data?.message !== "undefined") {
          ErrorToast(err?.response?.data?.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const handleBackClick = () => {
    setSelectedProduct({});
    setSections([]);
    setAudioUrls([]);
    setCurrentSection(0);
    setExerciseId("");
    setCheckExercise(false);
    setAllProductsData({});
    dispatch(clearProductData(null));
    dispatch(clearCategoryData(null));
  };

  useEffect(() => {
    checkExerciseService();
  }, []);

  useEffect(() => {
    getCategoryByLanguageIdUser(userDetails?.user?.language_id);
  }, [userDetails]);

  return (
    <>
      <Helmet>
        <title>Practice Dialogue | PhrasePulse</title>
      </Helmet>
      {!isLoading ? (
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">
                    Practice Dialogue
                    {!exerciseId && (
                      <button
                        className="btn btn-danger btnstl float-end"
                        onClick={() => handleBackClick()}
                      >
                        Back
                      </button>
                    )}
                  </h3>
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <span onClick={() => navigate("/user/dashboard")}>
                        Dashboard
                      </span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Exercise</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  {checkExercise === false &&
                    Object.keys(allProductsData)?.length === 0 &&
                    Object.keys(selectedProduct)?.length === 0 && (
                      <SelectCategory
                        randomProduct={randomProduct}
                        setRandomProduct={setRandomProduct}
                        category={category}
                        initialValues={initialValues}
                        allCategory={allCategory}
                        setInitialValues={setInitialValues}
                        getProductRendomByCategoryId={
                          getProductRendomByCategoryId
                        }
                        getProductsByCategoryId={getProductsByCategoryId}
                      />
                    )}

                  {Object.keys(selectedProduct).length > 0 && !exerciseId && (
                    <Sections
                      selectedProduct={selectedProduct}
                      handleStartExersice={handleStartExersice}
                    />
                  )}

                  {exerciseId && (
                    <StartTest
                      selectedProduct={selectedProduct}
                      sections={sections}
                      audioUrls={audioUrls}
                      exerciseId={exerciseId}
                      attemptSections={attemptSections}
                      currentSection={currentSection}
                      setCurrentSection={setCurrentSection}
                      handleAddSectionExersice={handleAddSectionExersice}
                      ConfirmationCancelExercise={ConfirmationCancelExercise}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <UserFooter />
        </div>
      ) : (
        <Loader loading={isLoading} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.reducers?.loading,
    userDetails: state?.reducers?.userDetails,
    category: state?.reducers?.category,
    product: state?.reducers?.product,
  };
};

const mapActionsToProps = (actions) => {
  return {};
};

export default connect(mapStateToProps, mapActionsToProps)(Exercise);
