import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GetNotificationPaginate } from "../../../Services/Api/notification";
import PaginationSimple from "../../../Utils/PaginationSimple";
import { Loader } from "../../../Utils/Loader";
import { UserFooter } from "../Layout";

const Notifications = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const limit = 10;

  const getNotificationPaginate = async (limit = "", page = "") => {
    setIsLoading(true);
    await GetNotificationPaginate({ limit: limit, page: page })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setNotifications(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setNotifications([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setNotifications([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const timeAgo = (time) => {
    return moment(time).fromNow();
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  useEffect(() => {
    getNotificationPaginate(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>Notifications | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Notifications</h3>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <span onClick={() => navigate("/user/dashboard")}>
                      Dashboard
                    </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Notification</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {!isLoading && (
                <>
                  {notifications?.map((notification, index) => (
                    <Accordion
                      className="accordion-items"
                      key={index}
                      defaultExpanded={index === 0}
                    >
                      <AccordionSummary
                        className="title user-notification-inner"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <p className="m-0">{notification?.title}</p>
                        <p className="m-0">
                          {timeAgo(new Date(notification?.createdOn))}
                        </p>
                      </AccordionSummary>
                      <AccordionDetails>
                        {notification?.message}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                  {notifications?.length === 0 && (
                    <div className="d-flex justify-content-center">
                      No Records Found.
                    </div>
                  )}
                </>
              )}
              {isLoading && <Loader loading={isLoading} />}
              <div className="d-flex justify-content-center">
                <PaginationSimple
                  totalItem={totalRecords}
                  itemsPerPage={limit}
                  page={page}
                  handleChangePage={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <UserFooter />
      </div>
    </>
  );
};

export default Notifications;
