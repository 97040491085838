import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { UserForgotPasswordService } from "../../Services/Api/auth";
import { SuccessToast, ErrorToast } from "../../Utils/SweetAlert";
import { Helmet } from "react-helmet-async";
import Information from "./Information";

const ForgotPassword = () => {
  const [showConfirm, setShowConfirm] = useState(false);

  return !showConfirm ? (
    <>
      <Helmet>
        <title>Forgot Password | PhrasePulse</title>
      </Helmet>
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            <Link to={`/`}>
              <img
                className="img-fluid logo-dark mb-2"
                src="/inner-assets/img/logo.png"
                alt="Logo"
              />
            </Link>
            <div className="loginbox">
              <div className="login-right">
                <div className="login-right-wrap">
                  <h1>Forgot Password</h1>
                  <p className="account-subtitle">
                    Enter your email to get a password reset link
                  </p>
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string().email().required("Email is required"),
                    })}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      await UserForgotPasswordService(values)
                        .then((res) => {
                          SuccessToast(res?.data?.message);
                          setSubmitting(false);
                          resetForm();
                          setShowConfirm(true);
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                    }) => {
                      return (
                        <Form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label className="form-control-label">
                              Email <span className="text-danger">*</span>
                            </label>
                            <Field
                              type="email"
                              onChange={handleChange}
                              className={`form-control ${
                                errors.email && touched.email
                                  ? "input-error"
                                  : null
                              }`}
                              name="email"
                              placeholder="Enter email"
                            />
                            <ErrorMessage
                              name="email"
                              component="span"
                              className="error"
                            />
                          </div>
                          <div className="form-group mb-0">
                            <button
                              className="btn btn-lg btn-block btn-primary w-100"
                              disabled={isSubmitting}
                              type="submit"
                            >
                              Reset Password&nbsp;&nbsp;
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                  <div className="text-center dont-have">
                    Remember your password?{" "}
                    <NavLink to="/user/auth/login">Login</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Information
      title={`Confirm Email`}
      caption={`Confirm Email`}
      decription={`Please check your email account to confirm your reset password request.`}
      short_desc={`Already have an accept email?`}
      link_text={`Login Now`}
      link={`/user/auth/login`}
    />
  );
};

export default ForgotPassword;
