import React from "react";
import { Navigate } from "react-router-dom";

const UserGuestRoute = ({ Component, updateHeaderFun }) => {
  const loginUser = localStorage.getItem("userAccessToken");

  let isLogin = false;
  if (loginUser !== null) {
    isLogin = true;
  }
  return isLogin ? (
    <Navigate to="/user/dashboard" />
  ) : (
    <Component
      updateHeaderFun={
        typeof updateHeaderFun !== "undefined" ? updateHeaderFun : ""
      }
    />
  );
};
export default UserGuestRoute;
