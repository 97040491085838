import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import Select from "react-select";
import { AdminFooter } from "../../Layout";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import {
  GetProductByIdService,
  EditProductService,
  GetCategoryListService,
} from "../../../Services/Api/product";
import { adminUploadFile } from "../../../Services/Api/fileUpload";
import EditSection from "./EditSection";

const EditProduct = () => {
  const stateData = useLocation();
  const { id } = stateData?.state || "";

  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sections, setSections] = useState();

  const signInSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    category_id: Yup.object().required("Category is required"),
  });

  const basicInitialValues = {
    name: product?.name || "",
    short_desc: product?.short_desc || "",
    long_desc: product?.long_desc || "",
    category_id:
      categories.find((option) => product?.category?._id === option.value) ||
      {},
    sections: product?.sections,
  };

  const getProductById = async (id) => {
    await GetProductByIdService(id)
      .then((res) => {
        if (res?.data?.data) {
          setProduct(res?.data?.data);
          setSections(res?.data?.data?.sections);
        }
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const CategoryList = async () => {
    await GetCategoryListService()
      .then((res) => {
        if (res?.data?.data?.length) {
          const categoryData = res?.data?.data?.map((category) => {
            return {
              label: `${category?.language?.name} > ${category?.name}`,
              value: category?._id,
            };
          });
          setCategories(categoryData);
        } else {
          setCategories([]);
        }
      })
      .catch((err) => {
        setCategories([]);
      });
  };

  const handleSection = () => {
    const newSection = {
      sectionId: sections.length + 1,
      file: [],
      options: [
        {
          optionId: 1,
          name: "",
        },
      ],
    };
    setSections((prevSections) => [...prevSections, newSection]);
  };

  const handleRemoveSection = (sectionId) => {
    setSections((prevSections) =>
      prevSections.filter((section) => section.sectionId !== sectionId)
    );
  };

  const handleOption = (sectionId) => {
    setSections((prevSections) => {
      return prevSections.map((section) => {
        if (section.sectionId === sectionId) {
          const newOption = {
            optionId: section.options.length + 1,
            name: "",
          };
          return {
            ...section,
            options: [...section.options, newOption],
          };
        }
        return section;
      });
    });
  };

  const handleRemoveOption = (sectionId, optionId) => {
    setSections((prevSections) => {
      return prevSections.map((section) => {
        if (section.sectionId === sectionId) {
          return {
            ...section,
            options: section.options.filter(
              (option) => option.optionId !== optionId
            ),
          };
        }
        return section;
      });
    });
  };

  const handleAudioFile = async (event, sectionId) => {
    const selectedFile = event.currentTarget.files[0];
    let filepath = "";
    if (selectedFile) {
      try {
        let fileRes = await adminUploadFile(selectedFile);
        filepath = fileRes?.data?.data?.path || "";
        if (filepath) {
          setSections((prevSections) => {
            return prevSections.map((section) => {
              if (section.sectionId === sectionId) {
                return {
                  ...section,
                  file: filepath,
                };
              }
              return section;
            });
          });
        }
      } catch (error) {}
    }
  };

  const handleOptionValue = (value, sectionId, optionId) => {
    const updatedSections = [...sections];
    updatedSections[sectionId - 1].options[optionId - 1].name = value;
    setSections(updatedSections);
  };

  useEffect(() => {
    getProductById(id);
    CategoryList();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Edit Product | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Edit Product</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/product">Products</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Product</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/product" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={basicInitialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postData = {
                        name: values.name,
                        short_desc: values?.short_desc,
                        long_desc: values?.long_desc,
                        category_id: values.category_id?.value,
                        sections: sections,
                      };
                      await EditProductService(id, postData)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message ||
                              "Product created successfully."
                          );
                          setSubmitting(false);
                          navigate("/admin/Product");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        values,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Name <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="name"
                                  placeholder="Enter name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Category{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={categories}
                                  className={`z-index-999`}
                                  onChange={(e) => {
                                    setFieldValue("category_id", e);
                                  }}
                                  name="category_id"
                                  value={values.category_id}
                                  placeholder="Select category"
                                />
                                <ErrorMessage
                                  name="category_id"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Short Description</label>
                                <Field
                                  as="textarea"
                                  className={`form-control`}
                                  name={`short_desc`}
                                  onChange={(e) => {
                                    setFieldValue("short_desc", e.target.value);
                                  }}
                                  value={values?.short_desc}
                                  placeholder="Enter Short Description"
                                />
                                <ErrorMessage
                                  name={`short_desc`}
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Long Description</label>
                                <Field
                                  as="textarea"
                                  className={`form-control`}
                                  name={`long_desc`}
                                  onChange={(e) => {
                                    setFieldValue("long_desc", e.target.value);
                                  }}
                                  value={values?.long_desc}
                                  placeholder="Enter Long Description"
                                />
                                <ErrorMessage
                                  name={`long_desc`}
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              {sections?.map((section) => (
                                <EditSection
                                  key={section.sectionId}
                                  sectionCount={sections?.length}
                                  section={section}
                                  handleSection={handleSection}
                                  handleRemoveSection={handleRemoveSection}
                                  handleOption={handleOption}
                                  handleAudioFile={handleAudioFile}
                                  handleOptionValue={handleOptionValue}
                                  handleRemoveOption={handleRemoveOption}
                                  errors={errors}
                                  touched={touched}
                                />
                              ))}
                              <div className="form-group">
                                <div className="add-new-section">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleSection()}
                                  >
                                    Add More Section
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Update Product
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProduct;
