import Api from "../../Utils/Axios";
import UserApi from "../../Utils/UserAxios";

/***************** Admin API **********************/

export const AddCategoryService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/category/store", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetCategoryPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await Api.get(`/category/paginate?per_page=${limit}&page=${page}`)
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteCategoryById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/category/delete/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const ActiveInactiveCategory = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/category/change-status/${id}`, {}));
    } catch (error) {
      reject(error);
    }
  });
};

export const SetFeatureCategory = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/category/change-feature/${id}`, {}));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetCategoryByIdService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/category/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const EditCategoryService = async (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/category/update/${id}`, data));
    } catch (error) {
      reject(error);
    }
  });
};

/***************** User API **********************/

export const GetAllCategory = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/category/list`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetCategoryByLanguageIdUser = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/category/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};
