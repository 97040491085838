import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AdminRoutes from "./AdminRoutes";
import UserRoutes from "./UserRoutes";
import AuthRoutes from "./AuthRoutes";
import AuthUserRoutes from "./AuthUserRoutes";
import FrontRoutes from "./FrontRoutes";

export default function Routings() {
  useEffect(() => {}, []);

  return (
    <>
      <Routes>
        <>
          <Route exact path="/*" element={<FrontRoutes />} />
          <Route path="/user/*" element={<UserRoutes />} />
          <Route path="/user/auth/*" element={<AuthUserRoutes />} />
          <Route path="/admin/*" element={<AdminRoutes />} />
          <Route path="/admin/auth/*" element={<AuthRoutes />} />
        </>
      </Routes>
    </>
  );
}
