import React, { useState } from "react";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";
import { SelectLanguage } from "../../../Services/Api/language";

const LanguageModal = (props) => {
  const { userData, languages } = props;
  const userId = userData?.user?._id || "";
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSave = async () => {
    setSubmitting(true);
    if (userId && selectedLanguage) {
      const postData = { language_id: selectedLanguage };
      await SelectLanguage(userId, postData)
        .then((res) => {
          if (res?.status) {
            SuccessToast(
              res?.data?.message || "Language Selected successfully."
            );
            setSubmitting(false);
            window.location.reload();
          } else {
            ErrorToast(res?.message || "Server Error!!");
          }
        })
        .catch((err) => {
          if (typeof err.response.data.message !== "undefined") {
            ErrorToast(err.response.data.message || "Server Error!!");
          } else {
            ErrorToast(err?.message || "Server Error!!");
          }
          setSubmitting(false);
        });
    } else {
      ErrorToast("Please select language");
      setSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        className="language-modal"
        show={true}
        backdropClassName={`language-modal`}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Select Language</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid={true} className="content">
            <Row className="justify-content-center">
              <Col lg={8} md={8} xs={12}>
                <Select
                  options={languages}
                  onChange={(event) => {
                    setSelectedLanguage(event?.value);
                  }}
                  name="language_id"
                  placeholder="Select language"
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={isSubmitting}
          >
            Select Language
            {isSubmitting && <i className="fas fa-spinner fa-spin"></i>}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LanguageModal;
