import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Information = ({
  title,
  caption,
  decription,
  short_desc,
  link,
  link_text,
}) => {
  return (
    <>
      <Helmet>
        <title>{title} | PhrasePulse</title>
      </Helmet>
      <div>
        <div className="main-wrapper login-body">
          <div className="login-wrapper">
            <div className="container">
              <Link to={`/`}>
                <img
                  className="img-fluid logo-dark mb-2"
                  src="/inner-assets/img/logo.png"
                  alt="Logo"
                />
              </Link>
              <div className="loginbox">
                <div className="login-right">
                  <div className="login-right-wrap">
                    <h1 className="title-text mb-4">{caption}</h1>
                    <p className="text-center mb-3">{decription}</p>
                    <div className="text-center dont-have register-link-client">
                      {short_desc} <Link to={link}>{link_text}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Information;
