import axios from 'axios';
export const baseURL = process.env.REACT_APP_API_BASE_URL;

const Api = axios.create({
  baseURL,
});

Api.interceptors.request.use((req) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    req.headers.Authorization = `${token}`;
  }
  return req;
});

Api.defaults.headers.post['Content-Type'] = 'multipart/form-data';

Api.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('adminData');
    window.location.assign('/admin');
  }
});

export const adminUploadFile = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/file/upload", formData));
    } catch (error) {
      reject(error);
    }
  });
};