import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Privacy Policy | PhrasePulse</title>
      </Helmet>
      <div className="inner-page">
        <Container>
          <Row>
            <Col>
              <div className="common-page mt-md-5 mt-lg-5">
                <h1>Privacy Policy</h1>
              </div>
              <br />
              <div>
                <p>
                  This Privacy Policy governs the manner in which
                  PhrasePulse.com, operated by Phrasepulse.com, collects, uses,
                  maintains, and discloses information collected from users
                  (each, a "User") of the PhrasePulse.com website ("Site").
                </p>

                <p>
                  <b>Collection of Personal Information</b>
                </p>
                <p>
                  PhrasePulse.com may, from time to time, receive and store
                  personal information you enter into our Mobile App, provided
                  to us directly, or given to us in other forms. This may
                  include basic information such as your name, phone number,
                  address, and email address, as well as additional information
                  provided during feedback, surveys, or interactions with our
                  customer support.
                </p>

                <p>
                  <b>Use of Information Collected</b>
                </p>
                <p>
                  We may use the collected information for purposes to which you
                  have consented, including communication, account maintenance,
                  managing payments, product improvement, avoiding service
                  misuse, analyzing consumer trends, marketing, legal
                  compliance, and security of your personal information.
                </p>

                <p>
                  <b>Children's Online Privacy Protection</b>
                </p>
                <p>
                  PhrasePulse.com understands that the privacy of children is of
                  utmost importance and does not knowingly collect Personal
                  Information from users who are under 18 years of age.
                </p>

                <br />
                <h4>Parental Consent</h4>
                <p>
                  <b>
                    In compliance with applicable laws, if you are under 18
                    years of age, parental/guardian consent may be required for
                    the collection and processing of your personal data.
                  </b>
                </p>
                <br />

                <p>
                  <b>Cookies and Other Tracking Technologies</b>
                </p>
                <p>
                  We use cookies and other tracking technologies to enhance user
                  experience and analyze site usage. Users may choose to set
                  their web browser to refuse cookies or to alert them when
                  cookies are being sent.
                </p>

                <p>
                  <b>Transparency</b>
                </p>
                <p>
                  Storing the user's consent in localStorage provides
                  transparency about the user's actions on the website. However,
                  it's crucial to also inform users about how their consent is
                  being used and provide them with options to manage their
                  preferences.
                </p>

                <p>
                  <b>Legal Compliance</b>
                </p>
                <p>
                  While localStorage can be used to store user consent, it's
                  just one part of a broader strategy for ensuring legal
                  compliance with regulations like the GDPR. Websites should
                  implement comprehensive cookie consent mechanisms that include
                  clear consent prompts, options for users to accept or reject
                  cookies, and mechanisms for users to manage their preferences.
                </p>

                <p>
                  <b>User Control</b>
                </p>
                <p>
                  It's important to give users control over their cookie
                  preferences beyond just setting a flag in localStorage. This
                  may involve providing options for users to change their
                  consent settings, revoke consent, or manage cookie preferences
                  through a dedicated interface.
                </p>

                <p>
                  <b>Data Security</b>
                </p>
                <p>
                  When storing sensitive information like user consent in
                  localStorage, it's important to consider data security
                  implications. While localStorage is accessible only to the
                  same origin (website) that set the data, it's still
                  client-side storage and may be susceptible to certain security
                  risks.
                </p>

                <p>
                  <b>Third-party Links</b>
                </p>
                <p>
                  PhrasePulse.com may contain links to third-party websites not
                  affiliated with us. We are not responsible for the content or
                  practices of these third-party sites and encourage users to
                  review their privacy policies before engaging in any
                  transactions.
                </p>

                <p>
                  <b>Disclaimer of Warranties and Limitation of Liability</b>
                </p>
                <p>
                  We do not guarantee uninterrupted, timely, secure, or
                  error-free service usage. Users agree that their use of our
                  service is at their sole risk.
                </p>

                <p>
                  <b>Termination</b>
                </p>
                <p>
                  These Terms of Service are effective unless and until
                  terminated by either you or us. We reserve the right to
                  terminate the agreement at any time for violation of the
                  terms.
                </p>

                <p>
                  <b>Prohibited User</b>
                </p>
                <p>
                  Users are prohibited from using the site for any unlawful
                  purpose, soliciting unlawful acts, violating regulations,
                  infringing intellectual property rights, harassing, submitting
                  false information, uploading viruses, collecting personal
                  information, spamming, engaging in obscene or immoral
                  activities, or interfering with security features.
                </p>

                <p>
                  <b>Errors, Inaccuracies, and Omissions</b>
                </p>
                <p>
                  Occasionally, there may be errors or inaccuracies in the
                  information provided on our site. We reserve the right to
                  correct any errors, inaccuracies, or omissions without prior
                  notice.
                </p>

                <p>
                  <b>Modifications to Our Services and Prices</b>
                </p>
                <p>
                  Fees and charges listed on PhrasePulse.com are subject to
                  change without notice. We reserve the right to modify or
                  discontinue the service (or any part or content thereof)
                  without notice at any time.
                </p>

                <p>
                  <b>Data Retention and Deletion</b>
                </p>
                <p>
                  We retain your personal information only for as long as
                  necessary for the purposes set out in this Privacy Policy. If
                  you wish to have your personal data deleted, please contact
                  us.
                </p>

                <p>
                  <b>General Notice</b>
                </p>
                <p>
                  We reserve the right to refuse service to anyone for any
                  reason at any time.
                </p>

                <p>
                  <b>Changes to This Privacy Policy</b>
                </p>
                <p>
                  PhrasePulse.com has the discretion to update this Privacy
                  Policy at any time. When we do, we will revise the updated
                  date at the bottom of this page. We encourage Users to
                  frequently check this page for any changes to stay informed
                  about how we are helping to protect the personal information
                  we collect. You acknowledge and agree that it is your
                  responsibility to review this Privacy Policy periodically and
                  become aware of modifications.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PrivacyPolicy;
