import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";
import FrontFaq from "./FrontFaq";
import {
  CurrencyConverter,
  InrToUsdConverter,
} from "../../Utils/CommanFunctions";

const Home = ({ selectedCurrency }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          PhrasePulse - Your Global Voice Playground: PhrasePulse Unleashed!
        </title>
        <meta
          name="description"
          content="Your Global Voice Playground: PhrasePulse Unleashed! Ready to unlock your full potential as an interpreter?
                    Phrasepulse.com offers the perfect practice ground. Explore
                    diverse dialogues, receive valuable feedback, and witness
                    your skills soar. Start your journey to interpreter mastery
                    today!"
        />
        <link rel="canonical" href="https://phrasepulse.com" />
      </Helmet>
      <section className="about-sec">
        <Container>
          <Row className="align-items-center">
            <Col sm={12} lg={6}>
              <div className="about-img">
                <img src="/inner-assets/img/front/about-img.png" alt="" />
              </div>
            </Col>
            <Col sm={12} lg={6}>
              <div className="about-content">
                <h2 className="sec-title">
                  Become a Master Interpreter with <span>PhrasePulse</span>
                </h2>
                <div className="text">
                  <p>
                    Ready to unlock your full potential as an interpreter?
                    Phrasepulse.com offers the perfect practice ground. Explore
                    diverse dialogues, receive valuable feedback, and witness
                    your skills soar. Start your journey to interpreter mastery
                    today!
                  </p>
                </div>
                <Row>
                  <Col sm={6} md={6} lg={6} xs={6}>
                    <div className="count-box">
                      <div className="icon">
                        <i className="fas fa-language"></i>
                      </div>
                      <div className="details">
                        <h3>5+</h3>
                        <p>Languages</p>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} md={6} lg={6} xs={6}>
                    <div className="count-box">
                      <div className="icon">
                        <i className="fas fa-microphone-alt"></i>
                      </div>
                      <div className="details">
                        <h3>700+</h3>
                        <p>Dialogues</p>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} md={6} lg={6} xs={6}>
                    <div className="count-box">
                      <div className="icon">
                        <i className="fas fa-font"></i>
                      </div>
                      <div className="details">
                        <h3>6000+</h3>
                        <p>Words</p>
                      </div>
                    </div>
                  </Col>

                  <Col sm={6} md={6} lg={6} xs={6}>
                    <div className="count-box">
                      <div className="icon">
                        <i className="fas fa-heart"></i>
                      </div>
                      <div className="details">
                        <h3>3</h3>
                        <p>Levels</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="how-work-sec">
        <Container>
          <h2 className="sec-title text-center">
            How Our Platform <span>Work</span>
          </h2>
          <Row>
            <Col sm={12} lg={6}>
              <div className="how-work-box">
                <span className="number">1</span>
                <h3>Practice Dialogues</h3>
                <p>
                  Explore a diverse array of dialogue scenarios curated for
                  various proficiency levels. Submit your dialogue practice and
                  receive tailored feedback from our team of experts within 48
                  hours.
                </p>
              </div>
            </Col>

            <Col sm={12} lg={6}>
              <div className="how-work-box">
                <span className="number">2</span>
                <h3>Enhance Vocabulary</h3>
                <p>
                  Gain access to extensive vocabulary lists accompanied by
                  pronunciation guides. Improve your pronunciation skills with
                  audio recordings and effortlessly master new words.
                </p>
              </div>
            </Col>

            <Col sm={12} lg={6}>
              <div className="how-work-box">
                <span className="number">3</span>
                <h3>Phrase Pulse Credits (PPC)</h3>
                <p>
                  Access exclusive features by utilizing PPC. Choose from three
                  tiers: Simple (7.5 PPC), Standard (10 PPC), and Complex (15
                  PPC). Utilize PPC to practice dialogues and vocabulary, or
                  contribute them to fellow users.
                </p>
              </div>
            </Col>

            <Col sm={12} lg={6}>
              <div className="how-work-box mb-0">
                <span className="number">4</span>
                <h3>Track Your Progress</h3>
                <p>
                  Keep tabs on your accuracy, fluency, and grammar/syntax skills
                  over time. Our tracking system enables you to pinpoint areas
                  for improvement and monitor your development.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="get-started-sec">
        <Container>
          <div className="content">
            <h2>Get Started Today</h2>
            <p>
              Sign up now to access our full suite of features and take your
              interpreting skills to the next level!
            </p>
            <Link className="btn btn-secondary" to="/register">
              Sign Up Now
            </Link>
          </div>
        </Container>
      </section>

      <section className="faq-sec" id="faq">
        <Container>
          <Row>
            <Col sm={12} md={4}>
              <div className="faq-img">
                <img src="/inner-assets/img/front/faq-img.png" alt="" />
              </div>
            </Col>
            <Col sm={12} md={8}>
              <div className="faq">
                <h2 className="sec-title">Have Questions?</h2>
                <FrontFaq
                  pageNew={0}
                  limitNew={5}
                  orderByNew={`createdOn`}
                  orderNew={`asc`}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pricing-sec">
        <Container>
          <h2 className="sec-title text-center">Pricing Plans</h2>
          <p className="text-center sub-title">
            Practice dialogues tailored to your proficiency level and learning
            goals using Phrase Pulse Credits (PPC). <br />
            <b>
              Each PPC is equivalent to {CurrencyConverter(selectedCurrency)}
              {InrToUsdConverter(selectedCurrency, 1)} ({selectedCurrency}).
            </b>
          </p>
          <Row>
            <Col lg={4} md={4} sm={12}>
              <div className="pricing-box">
                <h2 className="title">Simple Tire</h2>
                <h3 className="price">
                  7.5 PPC ({CurrencyConverter(selectedCurrency)}
                  {InrToUsdConverter(selectedCurrency, 7.5)})
                </h3>
                <ul>
                  <li>
                    Practice simple dialogues with basic vocabulary and minimal
                    grammar complexity.
                  </li>
                  <li>
                    Access beginner-friendly content suitable for learners
                    starting their language journey.
                  </li>
                </ul>
                <div className="text-center">
                  <Link className="btn btn-primary " to="/user/auth/login">
                    Practice Now
                  </Link>
                </div>
              </div>
            </Col>

            <Col lg={4} md={4} sm={12}>
              <div className="pricing-box">
                <h2 className="title">Standard Tire</h2>
                <h3 className="price">
                  10 PPC ({CurrencyConverter(selectedCurrency)}
                  {InrToUsdConverter(selectedCurrency, 10)})
                </h3>
                <ul>
                  <li>
                    Engage in dialogues with medium vocabulary and increased
                    content complexity.
                  </li>
                  <li>
                    Explore a wider range of topics and language structures to
                    enhance your proficiency.
                  </li>
                </ul>
                <div className="text-center">
                  <Link className="btn btn-primary " to="/user/auth/login">
                    Practice Now
                  </Link>
                </div>
              </div>
            </Col>

            <Col lg={4} md={4} sm={12}>
              <div className="pricing-box">
                <h2 className="title">Complex Tire</h2>
                <h3 className="price">
                  15 PPC ({CurrencyConverter(selectedCurrency)}
                  {InrToUsdConverter(selectedCurrency, 15)})
                </h3>
                <ul>
                  <li>
                    Dive into complex dialogues featuring advanced vocabulary
                    and intricate grammar structures.
                  </li>
                  <li>
                    Challenge yourself with longer passages and nuanced language
                    nuances to refine your language skills to an advanced level.
                  </li>
                </ul>
                <div className="text-center">
                  <Link className="btn btn-primary " to="/user/auth/login">
                    Practice Now
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Home;
