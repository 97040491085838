import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { AllAlphbet } from "../../../Utils/CommanFunctions";
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";
import PaginationSimple from "../../../Utils/PaginationSimple";
import {
  GetWordStackPaginate,
  GetWordStackFavourite,
  GetAllWordStackCategory,
} from "../../../Services/Api/wordStack";
import { UserFooter } from "../Layout";
// import { useSpeechSynthesis } from "react-speech-kit";
import { Loader } from "../../../Utils/Loader";

const WordStock = (props) => {
  const navigate = useNavigate();
  const { userDetails, browser } = props;
  const [page, setPage] = useState(0);
  const [useEffestStatus, setUseEffestStatus] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [wordStack, setWordStack] = useState([]);
  const [alphabet, setAlphabet] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [categoryId, setCategoryId] = useState(null);
  const [searchInputWord, setSearchInputWord] = useState("");
  const [categories, setCategories] = useState([]);
  // const { speak } = useSpeechSynthesis();
  const language_id = userDetails?.user?.language_id;
  const limit = 10;

  const getWordStackPaginate = async (
    limit = "",
    page = "",
    language_id = null,
    searchText = null,
    category_id = null
  ) => {
    setIsLoading(true);
    await GetWordStackPaginate({
      limit: limit,
      page: page,
      language_id: language_id || null,
      searchText: searchText || null,
      categoryId: category_id || null,
    })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setWordStack(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setWordStack([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setWordStack([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const getAllWordStackCategory = async () => {
    await GetAllWordStackCategory()
      .then((res) => {
        if (res?.data?.data?.length) {
          const categoriesData = res?.data?.data?.map((category) => {
            return {
              label: category?.name,
              value: category?._id,
            };
          });
          setCategories(categoriesData);
        } else {
          setCategories([]);
        }
      })
      .catch((err) => {
        setCategories([]);
      });
  };

  const getWordStackFavourite = async (id) => {
    setIsLoading(true);
    await GetWordStackFavourite(id)
      .then((res) => {
        setIsLoading(false);
        SuccessToast(res?.data?.message || "Updated");
        setUseEffestStatus(true);
      })
      .catch((err) => {
        setIsLoading(false);
        if (typeof err?.response?.data?.message !== "undefined") {
          ErrorToast(err?.response?.data?.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const handleAlphbetSearch = (text, alphabet = false) => {
    if (alphabet) {
      setAlphabet(text);
      setSearchInputWord("");
    } else {
      setAlphabet("");
    }
    setPage(0);
    setSearchWord(text);
  };

  const handleCategorySearch = (category_id) => {
    setCategoryId(categoryId !== category_id ? category_id : null);
    setPage(0);
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleSpeak = (text) => {
    if ("speechSynthesis" in window) {
      const value = new SpeechSynthesisUtterance(text);
      value.lang = "en-US";
      window.speechSynthesis.speak(value);
    } else {
      ErrorToast(
        "Text to speech is not supported in this browser. Please upgrade to a modern browser."
      );
    }
  };

  const handleTranslateSpeak = (lang, text) => {
    if ("speechSynthesis" in window) {
      const value = new SpeechSynthesisUtterance(text);
      console.log("value", value);
      if (browser === "Chrome") {
        if (lang === "Hindi") {
          value.lang = "hi-IN";
        } else if (lang === "Gujarati") {
          value.lang = "gu-IN";
        } else {
          value.lang = "gu-IN";
        }
      } else if (browser === "Firefox") {
        if (lang === "Hindi") {
          value.lang = "hi-IN";
        } else if (lang === "Gujarati") {
          value.lang = "gu-IN";
        } else {
          value.lang = "gu-IN";
        }
      } else if (browser === "Safari") {
        if (lang === "Hindi") {
          value.lang = "hi";
        } else if (lang === "Gujarati") {
          value.lang = "gu";
        } else {
          value.lang = "gu";
        }
      } else if (browser === "Edge") {
        if (lang === "Hindi") {
          value.lang = "hi";
        } else if (lang === "Gujarati") {
          value.lang = "gu";
        } else {
          value.lang = "gu";
        }
      } else if (browser === "Internet Explorer") {
        if (lang === "Hindi") {
          value.lang = "hi";
        } else if (lang === "Gujarati") {
          value.lang = "gu";
        } else {
          value.lang = "gu";
        }
      } else {
        if (lang === "Hindi") {
          value.lang = "hi";
        } else if (lang === "Gujarati") {
          value.lang = "gu";
        } else {
          value.lang = "gu";
        }
      }
      value.volume = 1;
      console.log("value end= ", value);
      window.speechSynthesis.speak(value);
    } else {
      ErrorToast(
        "Text to speech is not supported in this browser. Please upgrade to a modern browser."
      );
    }
  };

  useEffect(() => {
    getWordStackPaginate(limit, page, language_id, searchWord, categoryId);
  }, [limit, page, language_id, searchWord, categoryId, useEffestStatus]);

  useEffect(() => {
    getAllWordStackCategory();
  }, []);

  return (
    <>
      <Helmet>
        <title>Word Pulse | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Word Pulse</h3>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <span onClick={() => navigate("/user/dashboard")}>
                      Dashboard
                    </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Word Pulse</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>

          <div className="card mb-0">
            <div className="card-body">
              {AllAlphbet && (
                <div className="letter-list">
                  <h5 className="w-100">Search to Alphbet/Clusters</h5>
                  {AllAlphbet?.map((letter, index) => (
                    <div
                      className={
                        alphabet === letter
                          ? "letter-item active"
                          : "letter-item"
                      }
                      key={index}
                      onClick={() => handleAlphbetSearch(letter, true)}
                    >
                      {letter}
                    </div>
                  ))}
                </div>
              )}

              {categories && (
                <div className="word-category-wrapper">
                  <div className="word-category-list">
                    {categories?.map((category, index) => (
                      <div
                        className={
                          categoryId === category?.value
                            ? "word-category-item active"
                            : "word-category-item"
                        }
                        key={index}
                        onClick={() =>
                          handleCategorySearch(category?.value, true)
                        }
                      >
                        {category?.label}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="search-filter">
                <p className="mt-2 mb-1 d-none d-sm-block">Search to Word: </p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => setSearchInputWord(e.target.value)}
                />
                <button
                  className="btn btnstl btn-primary"
                  onClick={(e) => handleAlphbetSearch(searchInputWord)}
                >
                  Search
                </button>
                <button
                  className="btn btnstl btn-dark"
                  onClick={(e) => window.location.reload()}
                >
                  Reset
                </button>
              </div>

              {!isLoading && (
                <div className="table-responsive custom-pagination">
                  <table className="table table-center table<img<image-hover datatable">
                    <thead className="thead-light">
                      <tr>
                        <th>Original</th>
                        <th>Listen</th>
                        <th>Translate</th>
                        <th>Listen</th>
                        <th>Favorite</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wordStack?.map((word, index) => (
                        <tr key={index}>
                          <td>{word?.first_language || ""}</td>
                          <td>
                            <Button
                              variant="outline-primary"
                              className="play-btn p-0"
                              onClick={() => handleSpeak(word?.first_language)}
                            >
                              <PlayArrowOutlinedIcon />
                            </Button>
                          </td>
                          <td>{word?.second_language || ""}</td>
                          <td>
                            <Button
                              variant="outline-primary"
                              className="play-btn p-0"
                              onClick={() =>
                                handleTranslateSpeak(
                                  word?.language?.name,
                                  word?.second_language
                                )
                              }
                            >
                              <PlayArrowOutlinedIcon />
                            </Button>
                          </td>
                          <td>
                            <Button
                              variant="outline-primary"
                              onClick={() => getWordStackFavourite(word?._id)}
                            >
                              {word?.favouriteWordStack ? (
                                <FavoriteIcon />
                              ) : (
                                <FavoriteBorderIcon />
                              )}
                            </Button>
                          </td>
                        </tr>
                      ))}
                      {wordStack?.length === 0 && (
                        <tr>
                          <td colSpan={4}>No Word Found.</td>
                        </tr>
                      )}
                      {isLoading && (
                        <tr>
                          <Loader loading={isLoading} />
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="d-flex justify-content-center">
                <PaginationSimple
                  totalItem={totalRecords}
                  itemsPerPage={limit}
                  page={page}
                  handleChangePage={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <UserFooter />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.reducers?.loading,
    userDetails: state?.reducers?.userDetails,
    browser: state?.reducers?.browser,
    device: state?.reducers?.device,
  };
};

const mapActionsToProps = (actions) => {
  return {};
};

export default connect(mapStateToProps, mapActionsToProps)(WordStock);
