import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GetFaqPaginate } from "../../../Services/Api/faq";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PaginationSimple from "../../../Utils/PaginationSimple";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Loader } from "../../../Utils/Loader";
import { UserFooter } from "../Layout";

const Faq = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [faqs, setFaqs] = useState([]);
  const limit = 10;

  const getFaqPaginate = async (limit = "", page = "") => {
    setIsLoading(true);
    await GetFaqPaginate({ limit: limit, page: page })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setFaqs(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setFaqs([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setFaqs([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  useEffect(() => {
    getFaqPaginate(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>FAQ's | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">FAQ</h3>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <span onClick={() => navigate("/user/dashboard")}>
                      Dashboard
                    </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Faq</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="faq-hero">
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <h3>Frequently Asked Questions</h3>
                  <div className="text">
                    <p>
                      There may be many aspects to a good content piece, but
                      here we will discuss some of the indispensable elements
                      that must be imbibed in every web content work and is
                      essential for all content writers to know:
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2">
                  <div className="faq-img">
                    <img
                      className="img-responsive"
                      src="/inner-assets/img/faq.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              {!isLoading && (
                <>
                  {faqs?.map((faq, index) => (
                    <Accordion
                      className="accordion-items"
                      key={index}
                      defaultExpanded={index === 0}
                    >
                      <AccordionSummary
                        className="title"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {faq.question}
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          dangerouslySetInnerHTML={{ __html: faq?.answer }}
                        ></div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                  {faqs.length === 0 && (
                    <div className="d-flex justify-content-center">
                      No Records Found.
                    </div>
                  )}
                </>
              )}
              {isLoading && <Loader loading={isLoading} />}
              <div className="d-flex justify-content-center">
                <PaginationSimple
                  totalItem={totalRecords}
                  itemsPerPage={limit}
                  page={page}
                  handleChangePage={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
        <UserFooter />
      </div>
    </>
  );
};

export default Faq;
