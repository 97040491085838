import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Person2Icon from "@mui/icons-material/Person2";
import LanguageIcon from "@mui/icons-material/Language";
import CategoryIcon from "@mui/icons-material/Category";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

const AdminSidebar = ({ openSidebar, isMobile, setSidebarStatus }) => {
  const adminData = JSON.parse(localStorage?.getItem("adminData"));
  const agent_id = adminData?.user?.agent_id ? true : false;
  const [userManagementHideShow, setUserManagementHideShow] = useState("none");

  const [arrowUserManagement, setArrowUserManagement] = useState("menu-arrow");

  useEffect(() => {
    setArrowUserManagement(
      userManagementHideShow === "block"
        ? "menu-arrow menu-arrow-down"
        : "menu-arrow"
    );
  }, [userManagementHideShow]);

  const menuclick = (value) => {
    isMobile ? setSidebarStatus("close") : setSidebarStatus("open");
    setUserManagementHideShow("none");

    if (value === "usermanagement") {
      setUserManagementHideShow("block");
    }
  };

  return (
    <div
      className="sidebar-inner"
      id="sidebar-inner"
      style={
        openSidebar === true ? { marginLeft: "0px" } : { marginLeft: "-225px" }
      }
    >
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <div className="logo">
            <Link to="/admin/dashboard" className="logo logo-small">
              <img src="/inner-assets/img/logo-admin.png" alt="Logo" />
            </Link>
            {isMobile && (
              <button onClick={() => setSidebarStatus("close")}>
                <i className="fas fa-times"></i>
              </button>
            )}
          </div>
          <ul>
            <li onClick={() => menuclick("dashboard")}>
              <NavLink to="/admin/dashboard">
                <DashboardIcon />
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li onClick={() => menuclick("dashboard")}>
              <NavLink to="/admin/profile">
                <Person2Icon />
                <span>Your Profile</span>
              </NavLink>
            </li>
            <li className="submenu">
              <Link to="#">
                <div
                  onClick={() => {
                    setUserManagementHideShow(
                      userManagementHideShow === "none" ? "block" : "none"
                    );
                  }}
                >
                  <span className="role_icon">
                    <ManageAccountsIcon />
                  </span>
                  <span>Users Management</span>{" "}
                  <span className={arrowUserManagement} />
                </div>
              </Link>
              <ul style={{ display: userManagementHideShow }}>
                {!agent_id && (
                  <li>
                    <NavLink
                      onClick={() => menuclick("usermanagement")}
                      to="/admin/users"
                    >
                      Admin Users
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    onClick={() => menuclick("usermanagement")}
                    to="/admin/client/sub-users"
                  >
                    Client Users
                  </NavLink>
                </li>
              </ul>
            </li>
            {!agent_id && (
              <li onClick={() => menuclick("dashboard")}>
                <NavLink to="/admin/language">
                  <LanguageIcon />
                  <span>Languagies</span>
                </NavLink>
              </li>
            )}
            {!agent_id && (
              <li onClick={() => menuclick("category")}>
                <NavLink to="/admin/category">
                  <CategoryIcon />
                  <span>Categories</span>
                </NavLink>
              </li>
            )}
            {!agent_id && (
              <li onClick={() => menuclick("product")}>
                <NavLink to="/admin/product">
                  <ProductionQuantityLimitsIcon />
                  <span>Products</span>
                </NavLink>
              </li>
            )}
            <li onClick={() => menuclick("exercise")}>
              <NavLink to="/admin/exercise">
                <InventoryOutlinedIcon />
                <span>Exercises</span>
              </NavLink>
            </li>
            {!agent_id && (
              <li onClick={() => menuclick("faq")}>
                <NavLink to="/admin/faq">
                  <QuizOutlinedIcon />
                  <span>FAQ's</span>
                </NavLink>
              </li>
            )}
            {!agent_id && (
              <li onClick={() => menuclick("word-category")}>
                <NavLink to="/admin/word-category">
                  <CategoryIcon />
                  <span>Word Categories</span>
                </NavLink>
              </li>
            )}
            {!agent_id && (
              <li onClick={() => menuclick("word-stack")}>
                <NavLink to="/admin/word-stack">
                  <InventoryOutlinedIcon />
                  <span>Word Pulses</span>
                </NavLink>
              </li>
            )}
            {!agent_id && (
              <li onClick={() => menuclick("feedback")}>
                <NavLink to="/admin/feedback">
                  <ChatOutlinedIcon />
                  <span>Feedbacks</span>
                </NavLink>
              </li>
            )}
            {!agent_id && (
              <li onClick={() => menuclick("support")}>
                <NavLink to="/admin/support">
                  <SupportAgentOutlinedIcon />
                  <span>Supports</span>
                </NavLink>
              </li>
            )}
            {!agent_id && (
              <li onClick={() => menuclick("support")}>
                <NavLink to="/admin/contact-us">
                  <PermContactCalendarIcon />
                  <span>Contact us</span>
                </NavLink>
              </li>
            )}
            {!agent_id && (
              <li onClick={() => menuclick("credit")}>
                <NavLink to="/admin/credit">
                  <PaymentsOutlinedIcon />
                  <span>Credits</span>
                </NavLink>
              </li>
            )}
            <li onClick={() => menuclick("logout")}>
              <NavLink to="/admin/logout">
                <span className="icon">
                  <LogoutOutlinedIcon />
                </span>
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default AdminSidebar;
