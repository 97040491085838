import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import FrontLayout from "./FrontLayout";
import {
  UserRegister,
  ResetPassword,
  ForgotPassword,
} from "../Components/Front";
import Home from "../Components/Front/Home";
import PrivacyPolicy from "../Components/Front/PrivacyPolicy";
import Refund from "../Components/Front/Refund";
import TermCondition from "../Components/Front/TermCondition";
import VerifyUser from "../Utils/VerifyUser";
import ContactUs from "../Components/Front/ContactUs";
import FAQ from "../Components/Front/FAQ";
import NotFound from "../Components/Front/NotFound";

const FrontRoutes = () => {
  useEffect(() => {}, []);

  return (
    <>
      <Routes>
        <Route
          exact
          path="/"
          element={<FrontLayout fullBanner={true} Component={Home} />}
        />
        <Route
          exact
          path="/privacy-policy"
          element={<FrontLayout fullBanner={false} Component={PrivacyPolicy} />}
        />
        <Route
          exact
          path="/refund-policy"
          element={<FrontLayout fullBanner={false} Component={Refund} />}
        />
        <Route
          exact
          path="/term-and-condition"
          element={<FrontLayout fullBanner={false} Component={TermCondition} />}
        />
        <Route
          exact
          path="/contact"
          element={<FrontLayout fullBanner={false} Component={ContactUs} />}
        />
        <Route
          exact
          path="/faq"
          element={<FrontLayout fullBanner={false} Component={FAQ} />}
        />
        <Route exact path="/register" Component={UserRegister} />
        <Route exact path="/reset" Component={ResetPassword} />
        <Route exact path="/forgot-password" Component={ForgotPassword} />
        <Route exact path="/verify" Component={VerifyUser} />
        <Route
          exact
          path="/*"
          element={<FrontLayout fullBanner={false} Component={NotFound} />}
        />
      </Routes>
    </>
  );
};
export default FrontRoutes;
