import axios from "axios";
export const baseURL = process.env.REACT_APP_API_USER_BASE_URL;

const UserApi = axios.create({
  baseURL,
});

UserApi.interceptors.request.use((req) => {
  const token = localStorage.getItem("userAccessToken");
  if (token) {
    req.headers.Authorization = `${token}`;
  }
  return req;
});

UserApi.defaults.headers.post["Content-Type"] = "multipart/form-data";

UserApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("userAccessToken");
      localStorage.removeItem("userData");
      window.location.assign("/");
    }
  }
);

export const userUploadFile = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/file/upload", formData));
    } catch (error) {
      reject(error);
    }
  });
};

export const userSupportUploadFile = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("is_support", true);
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/file/upload", formData));
    } catch (error) {
      reject(error);
    }
  });
};
