import Api from "../../Utils/Axios";
import UserApi from "../../Utils/UserAxios";

/***************** Admin API **********************/

export const AddFaqService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/faq/store", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetAllFaqService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/faq/list`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetFaqPaginateService = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await Api.get(
          `/faq/paginate?per_page=${limit}&page=${page}&order_by=createdOn&order=asc`
        )
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteFaqByIdService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/faq/delete/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const ActiveInactiveFaqService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/faq/change-status/${id}`, {}));
    } catch (error) {
      reject(error);
    }
  });
};

/***************** User API **********************/

export const GetFaqPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await UserApi.get(
          `/faq/paginate?per_page=${limit}&page=${page}&order_by=createdOn&order=asc`
        )
      );
    } catch (error) {
      reject(error);
    }
  });
};
