import Api from "../../Utils/Axios";
import UserAxios from "../../Utils/UserAxios";

/***************** Admin API **********************/

export const AddLanguageService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/language/store", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetLanguageList = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/language/list`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetLanguagePaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await Api.get(`/language/paginate?per_page=${limit}&page=${page}`)
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteLanguageById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/language/delete/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const ActiveInactiveLanguage = async (languageId) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/language/change-status/${languageId}`, {}));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetLanguageByIdService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/admin/languages/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const EditLanguageService = async (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.put(`/admin/languages/${id}`, data));
    } catch (error) {
      reject(error);
    }
  });
};

/***************** User API **********************/

export const GetAllLanguage = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserAxios.get(`/language/list`));
    } catch (error) {
      reject(error);
    }
  });
};

export const SelectLanguage = async (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserAxios.post(`/user/select-language/${id}`, data));
    } catch (error) {
      reject(error);
    }
  });
};
