import React, { useEffect, useState } from "react";
import { Header, Footer, Banner } from "../Components/Front/Layout";
import CookiesConsent from "../Utils/CookiesConsent";

const FrontLayout = ({ fullBanner, Component, updateHeaderFun }) => {
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);
  };
  useEffect(() => {}, []);

  return (
    <>
      <header>
        <Header />
      </header>
      <main>
        {fullBanner && <Banner fullBanner={fullBanner} />}
        <div className="main-content-wrapper">
          <Component
            updateHeaderFun={
              typeof updateHeaderFun !== "undefined" ? updateHeaderFun : ""
            }
            selectedCurrency={selectedCurrency}
          />
        </div>
      </main>
      <footer>
        <Footer
          selectedCurrency={selectedCurrency}
          handleCurrencySelect={handleCurrencySelect}
        />
      </footer>
      <CookiesConsent />
    </>
  );
};
export default FrontLayout;
