import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../Utils";
import {
  GetCategoryPaginate,
  ActiveInactiveCategory,
  SetFeatureCategory,
  DeleteCategoryById,
} from "../../../Services/Api/category";
import { AdminFooter } from "../../Layout";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  ErrorToast,
  Confirmation,
  SuccessToast,
} from "../../../Utils/SweetAlert";

const Category = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryListPagination, setCategoryListPagination] = useState([]);
  const navigate = useNavigate();

  const GetCategoryList = async (limit = "", page = "") => {
    setIsLoading(true);
    await GetCategoryPaginate({ limit: limit, page: page })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setCategoryListPagination(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setCategoryListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setCategoryListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    // await GetCategoryList(limit, pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    // await GetCategoryList(parseInt(event.target.value, 10), 0);
  };

  const deleteCategoryById = (categoryId) => {
    Confirmation("Want to delete category?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await DeleteCategoryById(categoryId)
          .then((res) => {
            SuccessToast(res?.data?.message || "Category Deleted.");
            GetCategoryList(limit, page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  const changeActive = (category, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        await ActiveInactiveCategory(category?._id)
          .then((res) => {
            SuccessToast(res?.data.message || "Status Updated");
            GetCategoryList(limit, page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  const changeFeature = (category, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        await SetFeatureCategory(category?._id)
          .then((res) => {
            SuccessToast(res?.data.message || "Status Updated");
            GetCategoryList(limit, page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  useEffect(() => {
    GetCategoryList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>Categories | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Categories</h3>
              </div>
              <div className="col-auto">
                <Link to="/admin/add-category" className="btn btn-primary me-1">
                  <i className="fas fa-plus" /> Add Category
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>Name</th>
                          <th>Language</th>
                          <th>Credit</th>
                          <th>Status</th>
                          <th>Feature</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {categoryListPagination.map((category, index) => (
                              <tr key={index}>
                                <td>
                                  <h2 className="table-avatar">
                                    <Link to="#">{category?.name || ""}</Link>
                                  </h2>
                                </td>
                                <td>{category?.language?.name || ""}</td>
                                <td>{category?.credit || 0}</td>
                                <td>
                                  {category?.status === false && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-danger"
                                      onClick={() => {
                                        changeActive(
                                          category,
                                          "Want to active category?"
                                        );
                                      }}
                                    >
                                      Inactive
                                    </button>
                                  )}
                                  {category?.status === true && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-success"
                                      onClick={() => {
                                        changeActive(
                                          category,
                                          "Want to deactivate category?"
                                        );
                                      }}
                                    >
                                      Active
                                    </button>
                                  )}
                                </td>
                                <td>
                                  {category?.is_feature === false && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-danger"
                                      onClick={() => {
                                        changeFeature(
                                          category,
                                          "Want to feature this category?"
                                        );
                                      }}
                                    >
                                      Feature
                                    </button>
                                  )}
                                  {category?.is_feature === true && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-success"
                                      onClick={() => {
                                        changeFeature(
                                          category,
                                          "Want to un-feature this category?"
                                        );
                                      }}
                                    >
                                      Featured
                                    </button>
                                  )}
                                </td>
                                <td className="text-center">
                                  <Tooltip
                                    title="Edit"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      navigate("/admin/edit-category", {
                                        state: { id: category?._id },
                                      })
                                    }
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Delete"
                                    className="btn btn-sm btn-white text-danger me-2"
                                    onClick={() =>
                                      deleteCategoryById(category?._id)
                                    }
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              </tr>
                            ))}
                            {categoryListPagination.length === 0 && (
                              <tr>
                                <td colSpan={6}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={6}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={6}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
