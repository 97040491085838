import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Select from "react-select";
import { Pagination } from "../../../Utils";
import {
  GetWordStackPaginateService,
  ActiveInactiveWordStackService,
  DeleteWordStackByIdService,
} from "../../../Services/Api/wordStack";
import { GetLanguageList } from "../../../Services/Api/language";
import { GetWordCategoryListService } from "../../../Services/Api/wordStackCategory";
import { AdminFooter } from "../../Layout";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  ErrorToast,
  Confirmation,
  SuccessToast,
} from "../../../Utils/SweetAlert";

const WordStack = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [wordStackListPagination, setWordStackListPagination] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchInputWord, setSearchInputWord] = useState(null);
  const [searchWord, setSearchWord] = useState(null);
  const [language_id, setLanguageId] = useState(null);
  const [category_id, setCategoryId] = useState(null);
  const navigate = useNavigate();

  const GetWordStackList = async (
    limit = "",
    page = "",
    language_id = null,
    category_id = null,
    searchText = null
  ) => {
    setIsLoading(true);
    await GetWordStackPaginateService({
      limit: limit,
      page: page,
      language_id: language_id || null,
      category_id: category_id || null,
      searchText: searchText || null,
    })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setWordStackListPagination(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setWordStackListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setWordStackListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const getLanguageList = async () => {
    await GetLanguageList()
      .then((res) => {
        if (res?.data?.data?.length) {
          const languageData = res?.data?.data?.map((language) => {
            return {
              label: language?.name,
              value: language?._id,
            };
          });
          setLanguages(languageData);
        } else {
          setLanguages([]);
        }
      })
      .catch((err) => {
        setLanguages([]);
      });
  };

  const getCategoriesList = async () => {
    await GetWordCategoryListService()
      .then((res) => {
        if (res?.data?.data?.length) {
          const categoriesData = res?.data?.data?.map((category) => {
            return {
              label: category?.name,
              value: category?._id,
            };
          });
          setCategories(categoriesData);
        } else {
          setCategories([]);
        }
      })
      .catch((err) => {
        setCategories([]);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteWordStackById = (WordStackId) => {
    Confirmation("Want to delete Word Pulse?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await DeleteWordStackByIdService(WordStackId)
          .then((res) => {
            SuccessToast(res?.data?.message || "WordStack Deleted.");
            setPage(page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  const changeActive = (WordStack, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        await ActiveInactiveWordStackService(WordStack?._id)
          .then((res) => {
            SuccessToast(res?.data.message || "Status Updated");
            setPage(page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  const handleAlphbetSearch = () => {
    if (searchInputWord?.length >= 1) {
      setSearchWord(searchInputWord);
    }
  };

  useEffect(() => {
    GetWordStackList(limit, page, language_id, category_id, searchWord);
  }, [limit, page, language_id, category_id, searchWord]);

  useEffect(() => {
    getLanguageList();
    getCategoriesList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Word Pulse | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Word Pulse</h3>
              </div>
              <div className="col-auto">
                <Link
                  to="/admin/add-word-stack"
                  className="btn btn-primary me-1"
                >
                  <i className="fas fa-plus" /> Add Word Pulse
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="search-filter">
                    <div className="col-sm-12 col-md-3 col-lg-3 mt-2">
                      <Select
                        options={languages}
                        onChange={(newVal) => {
                          setLanguageId(newVal?.value);
                        }}
                        name="language_id"
                        placeholder="Select Language"
                      />
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3 mt-2">
                      <Select
                        options={categories}
                        onChange={(newVal) => {
                          setCategoryId(newVal?.value);
                        }}
                        name="category_id"
                        placeholder="Select Category"
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      placeholder="Search"
                      onChange={(e) => setSearchInputWord(e?.target?.value)}
                      value={searchInputWord || ""}
                    />
                    <button
                      className="btn btnstl btn-primary"
                      onClick={(e) => handleAlphbetSearch()}
                    >
                      <SearchIcon />
                    </button>
                    <button
                      className="btn btnstl btn-dark"
                      onClick={(e) => window.location.reload()}
                    >
                      <RestartAltIcon />
                    </button>
                  </div>
                  <div className="table-responsive custom-pagination">
                    <table className="table table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>Language</th>
                          <th>Category</th>
                          <th>Original</th>
                          <th>Translate</th>
                          <th>Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {wordStackListPagination.map((wordStack, index) => (
                              <tr key={index}>
                                <td>
                                  <h2 className="table-avatar">
                                    {wordStack?.language?.name || ""}
                                  </h2>
                                </td>
                                <td>
                                  <h2 className="table-avatar">
                                    {wordStack?.category?.name || ""}
                                  </h2>
                                </td>
                                <td>
                                  <h2 className="table-avatar">
                                    {wordStack?.first_language || ""}
                                  </h2>
                                </td>
                                <td>
                                  <h2 className="table-avatar">
                                    {wordStack?.second_language || ""}
                                  </h2>
                                </td>
                                <td>
                                  {wordStack?.status === false && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-danger"
                                      onClick={() => {
                                        changeActive(
                                          wordStack,
                                          "Want to active Word Pulse?"
                                        );
                                      }}
                                    >
                                      Inactive
                                    </button>
                                  )}
                                  {wordStack?.status === true && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-success"
                                      onClick={() => {
                                        changeActive(
                                          wordStack,
                                          "Want to deactivate Word Pulse?"
                                        );
                                      }}
                                    >
                                      Active
                                    </button>
                                  )}
                                </td>
                                <td className="text-center">
                                  <Tooltip
                                    title="Edit"
                                    className="btn btn-sm btn-white text-success me-2"
                                    onClick={() =>
                                      navigate("/admin/edit-word-stack", {
                                        state: { id: wordStack?._id },
                                      })
                                    }
                                  >
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip
                                    title="Delete"
                                    className="btn btn-sm btn-white text-danger me-2"
                                    onClick={() =>
                                      deleteWordStackById(wordStack?._id)
                                    }
                                  >
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              </tr>
                            ))}
                            {wordStackListPagination.length === 0 && (
                              <tr>
                                <td colSpan={6}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={6}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={6}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default WordStack;
