import React from "react";

const UserFooter = () => {
  return (
    <div className="footer-admin">
      <footer className="text-center text-lg-start">
        <div className="text-center">Powered by PhrasePulse.</div>
      </footer>
    </div>
  );
};

export default UserFooter;
