export const LOADER_STATE = `LOADER_STATE`;
export const SET_USER_DATA = `SET_USER_DATA`;
export const CLEAR_USER_DATA = `CLEAR_USER_DATA`;
export const SET_BROWSER_DATA = `SET_BROWSER_DATA`;
export const SET_DEVICE_DATA = `SET_DEVICE_DATA`;
export const SET_CATEGORY_DATA = `SET_CATEGORY_DATA`;
export const CLEAR_CATEGORY_DATA = `CLEAR_CATEGORY_DATA`;
export const SET_PRODUCT_DATA = `SET_PRODUCT_DATA`;
export const CLEAR_PRODUCT_DATA = `CLEAR_PRODUCT_DATA`;

const initialState = {
  loading: false,
  userDetails: null,
  browser: null,
  device: null,
  category: null,
  product: null,
};

const updateObject = (stateToUpdate) => {
  return stateToUpdate;
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case LOADER_STATE:
      return updateObject({
        ...state,
        loading: action.payload,
      });
    case SET_USER_DATA:
      return updateObject({
        ...state,
        userDetails: action.payload,
      });
    case CLEAR_USER_DATA:
      return updateObject({
        ...state,
        userDetails: null,
      });
    case SET_BROWSER_DATA:
      return updateObject({
        ...state,
        browser: action.payload,
      });
    case SET_DEVICE_DATA:
      return updateObject({
        ...state,
        device: action.payload,
      });
    case SET_CATEGORY_DATA:
      return updateObject({
        ...state,
        category: action.payload,
      });
    case CLEAR_CATEGORY_DATA:
      return updateObject({
        ...state,
        category: null,
      });
    case SET_PRODUCT_DATA:
      return updateObject({
        ...state,
        product: action.payload,
      });
    case CLEAR_PRODUCT_DATA:
      return updateObject({
        ...state,
        product: null,
      });
    default:
      return state;
  }
}
