import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PaginationSimple from "../../../Utils/PaginationSimple";
import { userSupportUploadFile } from "../../../Services/Api/userFileUpload";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { GetSupportPaginate, AddSupport } from "../../../Services/Api/support";
import { UserFooter } from "../Layout";

const Support = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [supports, setSupports] = useState([]);
  const [attachmentFile, setAttachmentFile] = useState("");
  const limit = 10;
  const baseImageURL = process.env.REACT_APP_BASE_URL;

  const signInSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
    attachment: Yup.mixed().test(
      "fileType",
      "Only image files are allowed",
      (value) => {
        if (!value) return true; // Allow empty value
        const acceptedFormats = [
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/heic",
          "image/heif",
        ];
        return acceptedFormats.includes(value.type);
      }
    ),
  });

  const initialValues = {
    subject: "",
    message: "",
    attachment: "",
  };

  const getSupportPaginate = async (limit = "", page = "") => {
    setIsLoading(true);
    await GetSupportPaginate({ limit: limit, page: page })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setSupports(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setSupports([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setSupports([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleAttachFile = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      let filepath = "";
      try {
        let fileRes = await userSupportUploadFile(selectedFile);
        filepath = fileRes?.data?.data?.path || "";
      } catch (error) {}
      if (filepath) {
        setAttachmentFile(filepath);
      }
    }
  };

  const handleAttachmentDelete = () => {
    setAttachmentFile("");
  };

  useEffect(() => {
    getSupportPaginate(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>Support | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Support</h3>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <span onClick={() => navigate("/user/dashboard")}>
                      Dashboard
                    </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Support</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="faq-hero">
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <h3>We are happy to help!</h3>
                  <div className="text">
                    <p>
                      Before reaching out to our support team, please check our
                      FAQs section to see if your question has already been
                      answered.{" "}
                      <Link to={`/user/faq`} className="text-primary">
                        Frequently Asked Questions
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2">
                  <div className="faq-img">
                    <img
                      className="img-responsive w-auto"
                      src="/inner-assets/img/feedback.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Submit a Ticket</h4>
                </div>
                <div className="card-body">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={signInSchema}
                      validateOnChange
                      onSubmit={async (values, { setSubmitting }) => {
                        const postData = {
                          subject: values.subject,
                          message: values.message,
                          attachments: [{ file: attachmentFile }],
                        };
                        await AddSupport(postData)
                          .then((res) => {
                            SuccessToast(
                              res?.data?.message ||
                                "Support added successfully."
                            );
                            setSubmitting(false);
                            window.location.reload(true);
                          })
                          .catch((err) => {
                            if (
                              typeof err.response.data.message !== "undefined"
                            ) {
                              ErrorToast(
                                err.response.data.message || "Server Error!!"
                              );
                            } else {
                              ErrorToast(err?.message || "Server Error!!");
                            }
                            setSubmitting(false);
                          });
                      }}
                    >
                      {(formik) => {
                        const { errors, touched, isSubmitting } = formik;
                        return (
                          <Form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>
                                    Subject{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    className={`form-control ${
                                      errors.subject && touched.subject
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="subject"
                                    placeholder="Enter Subject"
                                  />
                                  <ErrorMessage
                                    name="subject"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Screen Shot</label>
                                  <Field
                                    type="file"
                                    multiple
                                    className={`form-control ${
                                      errors.attachment && touched.attachment
                                        ? "input-error"
                                        : null
                                    }`}
                                    name={`attachment`}
                                    onChange={(event) =>
                                      handleAttachFile(event)
                                    }
                                    disabled={attachmentFile ? true : false}
                                  />
                                  <span className="text-danger">
                                    Only Allow: PNG, JPEG, HEIC, HEIF
                                  </span>
                                  {attachmentFile && (
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={baseImageURL + attachmentFile}
                                        alt=""
                                        className="img-thumbnail me-2 w-50"
                                      />
                                      <Button
                                        variant="danger"
                                        className="btn border-0 bg-transparent text-danger"
                                        onClick={() => handleAttachmentDelete()}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    </div>
                                  )}
                                  <ErrorMessage
                                    name={`attachment`}
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                              <div className="clearfix"></div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>What's Your Query</label>
                                  <Field
                                    as="textarea"
                                    className={`form-control ${
                                      errors.message && touched.message
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="message"
                                    placeholder="Enter Query"
                                  />
                                  <ErrorMessage
                                    name="message"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="text-start">
                              <button
                                type="submit"
                                className="btn btn-primary btnstl"
                                disabled={isSubmitting}
                              >
                                Submit
                                {isSubmitting && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {supports?.length > 0 ? (
            <>
              <h4 className="card-title">Your Queries</h4>
              <div className="col-xs-12 col-sm-12 col-md-12">
                {!isLoading && (
                  <>
                    {supports?.map((support, index) => (
                      <Accordion
                        className="accordion-items"
                        key={index}
                        defaultExpanded={index === 0}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {support.subject}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="">
                            {support.message}
                            {support?.attachments &&
                              support?.attachments[0] &&
                              support?.attachments?.[0]?.file_url && (
                                <div className="m-2">
                                  <img
                                    src={support?.attachments?.[0]?.file_url}
                                    alt=""
                                    className="img-thumbnail me-2"
                                    style={{ maxWidth: "200px" }}
                                  />
                                </div>
                              )}
                            <div className="border-bottom"></div>
                            <p className="text-end">
                              Your Support ID: {support._id}
                            </p>
                          </div>
                          {support?.admin_message && support?.admin_subject && (
                            <div className="mt-5">
                              <div className="border-bottom"></div>
                              <h5 className="mb-2 mt-2">Admin Replied: </h5>
                              <b>{support?.admin_subject}</b>
                              <br />
                              {support?.admin_message}
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                    {supports?.length === 0 && (
                      <div className="d-flex justify-content-center">
                        No Records Found.
                      </div>
                    )}
                  </>
                )}
                {isLoading && (
                  <div className="d-flex justify-content-center">
                    Loading...
                  </div>
                )}
                <div className="d-flex justify-content-center">
                  <PaginationSimple
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <UserFooter />
      </div>
    </>
  );
};

export default Support;
