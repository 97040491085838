import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Components/Auth/Login";
import GuestRoute from "./GuestRoute";

const AuthRoutes = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<GuestRoute Component={Login} />} />
        <Route exact path="/login" element={<GuestRoute Component={Login} />} />
      </Routes>
    </>
  );
};
export default AuthRoutes;
