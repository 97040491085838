import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { AddUserService } from "../../../Services/Api/user";

const AddUser = () => {
  const navigate = useNavigate();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const signInSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .max(50, "Email can contain a maximum of 40 characters")
      .required("Email is required"),
    name: Yup.string()
      .min(4, "Name must have a minimum of 4 characters")
      .max(40, "Name can contain a maximum of 40 characters")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .required("Name is required"),
    password: Yup.string()
      .required("Password is required")
      .max(40, "Password can contain a maximum of 40 characters")
      .test(
        "uppderCase",
        "Must contain at least one uppercase character",
        (value) => /[A-Z]/.test(value)
      )
      .test(
        "lowerCase",
        "Must contain at least one lowercase character",
        (value) => /[a-z]/.test(value)
      )
      .test("oneNumber", "Must contain at least one number", (value) =>
        /[0-9]/.test(value)
      )
      .test(
        "oneSpecial",
        "Must contain at least one special character",
        (value) => /[!@#%&]/.test(value)
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    is_agent: "",
  };

  useEffect(() => {}, []);

  return (
    <>
      <Helmet>
        <title>Admin Add User | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Add User</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/users">Users</Link>
                  </li>
                  <li className="breadcrumb-item active">Add User</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/users" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postData = {
                        name: values.name,
                        contact_number: values.contact_number,
                        email: values.email,
                        password: values.password,
                        is_agent: values.is_agent,
                        role_id: values.role,
                      };
                      await AddUserService(postData)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "User created successfully."
                          );
                          setSubmitting(false);
                          navigate("/admin/users");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, isSubmitting } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Name <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="name"
                                  placeholder="Enter name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  className={`form-control ${
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="email"
                                  placeholder="Enter email"
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Password{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="inputWrap">
                                  <Field
                                    type={isShowPassword ? "text" : "password"}
                                    className={`form-control ${
                                      errors.password && touched.password
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="password"
                                    placeholder="Enter password"
                                  />
                                  <span
                                    onClick={() =>
                                      setIsShowPassword(!isShowPassword)
                                    }
                                    className={`fas ${
                                      isShowPassword ? "fa-eye-slash" : "fa-eye"
                                    } toggle-password-user`}
                                  />
                                </div>
                                <ErrorMessage
                                  name="password"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Confirm Password{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="inputWrap">
                                  <Field
                                    type={
                                      isShowConfirmPassword
                                        ? "text"
                                        : "password"
                                    }
                                    className={`form-control ${
                                      errors.confirm_password &&
                                      touched.confirm_password
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="confirm_password"
                                    placeholder="Confirm password"
                                  />
                                  <span
                                    onClick={() =>
                                      setIsShowConfirmPassword(
                                        !isShowConfirmPassword
                                      )
                                    }
                                    className={`fas ${
                                      isShowConfirmPassword
                                        ? "fa-eye-slash"
                                        : "fa-eye"
                                    } toggle-password-user`}
                                  />
                                </div>
                                <ErrorMessage
                                  name="confirm_password"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                            <div className="form-group">
                                <div className="inputWrap">
                                <label className="form-check-label me-3" htmlFor="is_agent"> Are you an agent? </label>
                                  <Field
                                    type="checkbox"
                                    className={`form-check-input ${
                                      errors.is_agent &&
                                      touched.is_agent
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="is_agent"
                                    id="is_agent"
                                  />
                                </div>
                                <ErrorMessage
                                  name="is_agent"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add User
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
