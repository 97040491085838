import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { AdminFooter } from "../../Layout";
import Card from "./Card";
import { GetAllDashBoardService } from "../../../Services/Api/comman";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardCount, setDashboardCount] = useState({});
  const adminData = JSON.parse(localStorage.getItem("adminData"));
  const agent_id = adminData?.user?.agent_id ? true : false;

  const getAllDashBoardService = async () => {
    setIsLoading(true);
    await GetAllDashBoardService()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data) {
          setDashboardCount(res?.data?.data);
        } else {
          setDashboardCount({});
        }
      })
      .catch((err) => {
        setDashboardCount({});
        setIsLoading(false);
      });
  };

  useEffect(() => {
    !agent_id && getAllDashBoardService();
    setIsLoading(false);
  }, [agent_id]);

  return (
    <>
      <Helmet>
        <title>Admin Dashboard | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          {!isLoading ? (
            !agent_id ? (
              <div className="row">
                <div className="col-xl-12 col-sm-12 col-12">
                  <h2>Welcome to Administrator</h2>
                </div>
                <div className="clearfix"></div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <Card
                    title={`Admin User`}
                    link={`/admin/users`}
                    count={dashboardCount?.admin_user || 0}
                  />
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <Card
                    title={`Client User`}
                    link={`/admin/client/sub-users`}
                    count={dashboardCount?.client_user || 0}
                  />
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <Card
                    title={`Languages`}
                    link={`/admin/language`}
                    count={dashboardCount?.languages || 0}
                  />
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <Card
                    title={`Categories`}
                    link={`/admin/category`}
                    count={dashboardCount?.categories || 0}
                  />
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <Card
                    title={`Products`}
                    link={`/admin/product`}
                    count={dashboardCount?.products || 0}
                  />
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <Card
                    title={`Pending Exercises`}
                    link={`/admin/exercise`}
                    count={dashboardCount?.pending_exercise || 0}
                  />
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <Card
                    title={`Completed Exercises`}
                    link={`/admin/exercise`}
                    count={dashboardCount?.completed_exercise || 0}
                  />
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <Card
                    title={`FAQ's`}
                    link={`/admin/faq`}
                    count={dashboardCount?.faqs || 0}
                  />
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <Card
                    title={`Word Stack`}
                    link={`/admin/word-stack`}
                    count={dashboardCount?.word_stacks || 0}
                  />
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <Card
                    title={`Feedback`}
                    link={`/admin/feedback`}
                    count={dashboardCount?.feedbacks || 0}
                  />
                </div>
                <div className="clearfix"></div>
                <AdminFooter />
              </div>
            ) : (
              <div className="row">
                <div className="col-xl-12 col-sm-12 col-12">
                  <h2>Welcome to Agent</h2>
                </div>
                <div className="clearfix"></div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <Card
                    title={`Client User`}
                    link={`/admin/client/sub-users`}
                  />
                </div>
                <div className="col-xl-4 col-sm-6 col-12">
                  <Card
                    title={`Completed Exercises`}
                    link={`/admin/exercise`}
                  />
                </div>
              </div>
            )
          ) : (
            <div className="d-block align-center">Loading...</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
