import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { agentAllowUrls } from "../Utils/CommanFunctions";

const PrivateRoute = ({ Component, updateHeaderFun }) => {
  const loginAdmin = localStorage.getItem("accessToken");
  const loginAdminData = JSON.parse(localStorage.getItem("adminData"));
  const loginUser = localStorage.getItem("userAccessToken");
  const location = useLocation();
  let isLogin = false;
  if (loginUser !== null || loginAdmin !== null) {
    isLogin = true;
  }

  if (loginAdminData?.user?.agent_id) {
    if (agentAllowUrls(location?.pathname)) {
      isLogin = true;
    } else {
      isLogin = false;
    }
  }
  return isLogin ? (
    <Component
      updateHeaderFun={
        typeof updateHeaderFun !== "undefined" ? updateHeaderFun : ""
      }
    />
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
