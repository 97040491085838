import axios from 'axios';
export const baseURL = process.env.REACT_APP_API_BASE_URL;

const Api = axios.create({
  baseURL,
});

Api.interceptors.request.use((req) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    req.headers.Authorization = `${token}`;
  }
  return req;
});

Api.defaults.headers.post['Content-Type'] = 'application/json';

Api.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('adminData');
    window.location.assign('/admin/auth/login');
  }
  return Promise.reject(error);
});

export default Api;
