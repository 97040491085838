import { BrowserRouter as Router } from "react-router-dom";
import Routings from "./routes";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "./Services/Api/store";
import NotSupported from "./Components/User/NotSupported";

var isIE = /*@cc_on!@*/ false || !!document.documentMode;
function App() {
  return isIE ? (
    <NotSupported />
  ) : (
    <div className="App">
      <HelmetProvider>
        <Provider store={store()}>
          <Router>
            <Routings />
          </Router>
        </Provider>
      </HelmetProvider>
    </div>
  );
}

export default App;
