import React from "react";

export const Loader = ({ loading }) => {
  return (
    loading && (
      <div className="d-flex justify-content-center vh-100">
        <div className="align-self-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    )
  );
};
