import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>404 Not Found | PhrasePulse</title>
      </Helmet>
      <div className="inner-page">
        <Container className="vh-70 container d-flex flex-column justify-content-center">
          <Row>
            <Col className="text-center">
              <div className="common-page mt-md-5 mt-lg-5">
                <h1>Ohh! Page Not Found</h1>
              </div>
              <p>Sorry, the page you are looking for does not exist.</p>
              <Link to={`/`} className="btn btn-primary">
                Back Home
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default NotFound;
