import Api from "../../Utils/Axios";
import UserApi from "../../Utils/UserAxios";

/***************** Admin API **********************/

export const GetFeedbackPaginateService = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await Api.get(`/feedback/paginate?per_page=${limit}&page=${page}`)
      );
    } catch (error) {
      reject(error);
    }
  });
};


/***************** User API **********************/

export const AddFeedback = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/feedback/store", data));
    } catch (error) {
      reject(error);
    }
  });
};