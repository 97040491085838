import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { GetProfileService } from "../../../Services/Api/user";

const Profile = () => {
  const [profileUserData, setProfileUserData] = useState({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    is_agent: "",
  });

  const getProfileService = async () => {
    await GetProfileService().then((res) => {
      if (res?.data?.data?.user) {
        setProfileUserData(res?.data?.data?.user);
      } else {
        setProfileUserData({});
      }
    });
  };

  const handleCopy = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        SuccessToast("Copied!");
      })
      .catch(() => {
        ErrorToast("Failed to copy!");
      });
  };

  useEffect(() => {
    getProfileService();
  }, []);

  return (
    <>
      <Helmet>
        <title>Profile | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Your Profile</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Your Profile</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={profileUserData}
                    validateOnChange
                  >
                    {() => {
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Name <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control`}
                                  name="name"
                                  placeholder="Enter name"
                                  value={profileUserData?.name || ""}
                                  disabled
                                  readOnly
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  className={`form-control`}
                                  name="email"
                                  placeholder="Enter email"
                                  value={profileUserData?.email || ""}
                                  disabled
                                  readOnly
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Phone <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control`}
                                  name="phone"
                                  placeholder="Enter phone"
                                  value={profileUserData?.phone || ""}
                                  disabled
                                  readOnly
                                />
                              </div>
                            </div>
                            {profileUserData?.agent_id && (
                              <div className="col-md-12">
                                <h4>Your Referral Info</h4>
                                <div className="form-group">
                                  <label>Referral Link</label>
                                  <div className="row">
                                    <div className="col-md-8">
                                      <div className="form-control">
                                        {profileUserData?.referal_link || ""}
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <span
                                        className="btn btn-primary me-1"
                                        onClick={() =>
                                          handleCopy(
                                            profileUserData?.referal_link || ""
                                          )
                                        }
                                      >
                                        Copy
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label>Referral Code</label>
                                  <div className="row">
                                    <div className="col-md-2">
                                      <div className="form-control">
                                        {profileUserData?.referal_code || ""}
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <span
                                        className="btn btn-primary me-1"
                                        onClick={() =>
                                          handleCopy(
                                            profileUserData?.referal_code || ""
                                          )
                                        }
                                      >
                                        Copy
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
