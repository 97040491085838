import React from "react";
import { Pagination } from "@mui/material";

const PaginationSimple = ({
  totalItem,
  itemsPerPage,
  page,
  handleChangePage,
}) => {
  return (
    <>
      <Pagination
        count={Math.ceil(totalItem / itemsPerPage)} // Calculate the total number of pages
        page={page + 1}
        onChange={(event, value) => handleChangePage(event, value - 1)} // Pagination is 0-based, while page index starts from 0
      />
    </>
  );
};

export default PaginationSimple;
