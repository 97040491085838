import React, { useMemo, useCallback, useRef } from "react";

import { useWavesurfer } from "@wavesurfer/react";
import Timeline from "../../../../node_modules/wavesurfer.js/dist/plugins/timeline.esm.js";

const SectionView = (props) => {
  const { urlIndex, originalAudioUrls, userAudioUrls } = props;

  const formatTime = (seconds) =>
    [seconds / 60, seconds % 60]
      .map((v) => `0${Math.floor(v)}`.slice(-2))
      .join(":");
  const [originalContainerRef, userContainerRef] = [useRef(null), useRef(null)];

  const {
    wavesurfer: originalWavesurfer,
    isPlaying: isPlayingOriginal,
    currentTime: currentTimeOriginal,
  } = useWavesurfer({
    container: originalContainerRef,
    height: 100,
    waveColor: "rgb(200, 0, 200)",
    progressColor: "rgb(100, 0, 100)",
    url: originalAudioUrls[urlIndex],
    plugins: useMemo(() => [Timeline.create()], []),
  });

  const {
    wavesurfer: userWavesurfer,
    isPlaying: isPlayingUser,
    currentTime: currentTimeUser,
  } = useWavesurfer({
    container: userContainerRef,
    height: 100,
    waveColor: "rgb(200, 0, 200)",
    progressColor: "rgb(100, 0, 100)",
    url: userAudioUrls[urlIndex],
    plugins: useMemo(() => [Timeline.create()], []),
  });

  const onPlayPause = useCallback(() => {
    if (originalWavesurfer) {
      originalWavesurfer.playPause();
    }
  }, [originalWavesurfer]);

  const onPlayPauseUser = useCallback(() => {
    if (userWavesurfer) {
      userWavesurfer.playPause();
    }
  }, [userWavesurfer]);

  return (
    <>
      <h4>Original Audio</h4>

      <div ref={originalContainerRef} />
      <p>Current time: {formatTime(currentTimeOriginal)}</p>
      <div
        style={{
          margin: "1em 0",
          display: "flex",
          gap: "1em",
        }}
      >
        <button
          type="button"
          className={`btn btn-primary`}
          onClick={onPlayPause}
          style={{ minWidth: "5em" }}
        >
          {isPlayingOriginal ? "Pause" : "Play"}
        </button>
      </div>

      <h4>User Audio</h4>
      <div ref={userContainerRef} />
      <p>Current time: {formatTime(currentTimeUser)}</p>
      <div
        style={{
          margin: "1em 0",
          display: "flex",
          gap: "1em",
        }}
      >
        <button
          type="button"
          className={`btn btn-primary`}
          onClick={onPlayPauseUser}
          style={{ minWidth: "5em" }}
        >
          {isPlayingUser ? "Pause" : "Play"}
        </button>
      </div>
    </>
  );
};

export default SectionView;
