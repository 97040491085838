import Api from "../../Utils/Axios";
import UserApi from "../../Utils/UserAxios";

/***************** Admin API **********************/

export const GetWordCategoryListService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/word_stack_category/list`));
    } catch (error) {
      reject(error);
    }
  });
};

export const AddWordStackCategoryService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/word_stack_category/store", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetWordStackCategoryPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await Api.get(
          `/word_stack_category/paginate?per_page=${limit}&page=${page}`
        )
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteWordStackCategoryById = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/word_stack_category/delete/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const ActiveInactiveWordStackCategory = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/word_stack_category/change-status/${id}`, {}));
    } catch (error) {
      reject(error);
    }
  });
};

export const SetFeatureWordStackCategory = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/word_stack_category/change-is-paid/${id}`, {}));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetWordStackCategoryByIdService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/word_stack_category/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const EditWordStackCategoryService = async (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/word_stack_category/update/${id}`, data));
    } catch (error) {
      reject(error);
    }
  });
};

/***************** User API **********************/

export const GetAllWordStackCategory = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/word_stack_category/list`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetWordStackCategoryByLanguageIdUser = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/word_stack_category/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};
