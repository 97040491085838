import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { UserUpdateProfileService } from "../../../Services/Api/auth";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { GetAllLanguage } from "../../../Services/Api/language";
import { Helmet } from "react-helmet-async";
import { UserFooter } from "../Layout";
import ChangePassword from "./ChangePassword";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Loader } from "../../../Utils/Loader";
import PhoneInput from "react-phone-number-input";

const Profile = (props) => {
  const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  const navigate = useNavigate();
  const { loading, userDetails } = props;
  const [allLangeage, setAllLangeage] = useState([]);
  const [loadingComponent, setLoadingComponent] = useState(false);
  const userId = userDetails?.user?._id || "";
  const referralCode = userDetails?.user?.agent_id || false;

  const getAllLanguage = async () => {
    setLoadingComponent(true);
    await GetAllLanguage().then((res) => {
      if (res?.data?.data?.length > 0) {
        let lanarr = res?.data?.data;
        lanarr = lanarr.map((value) => {
          return {
            label: value.name,
            value: value._id,
          };
        });
        setAllLangeage(lanarr);
        setLoadingComponent(false);
      } else {
        setAllLangeage([]);
        setLoadingComponent(false);
      }
    });
  };

  const initialValues = {
    name: userDetails?.user?.name || "",
    email: userDetails?.user?.email || "",
    phone_code: userDetails?.user?.phone_code || "",
    contact_number: userDetails?.user?.phone || "",
    language_id:
      allLangeage?.find(
        (option) => userDetails?.user?.language_id === option.value
      ) || {},
    referral: "",
  };

  const signInSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .max(50, "Email can contain a maximum of 40 characters")
      .required("Email is required"),
    referral: Yup.string()
      .min(6, "Code must have a minimum of 6 characters")
      .max(6, "Code can contain a maximum of 6 characters")
      .matches(/^[a-zA-Z0-9\s]+$/, "Only alphabets/numbers are allowed"),
    name: Yup.string()
      .min(4, "Name must have a minimum of 4 characters")
      .max(40, "Name can contain a maximum of 40 characters")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed")
      .required("Name is required"),
    language_id: Yup.object().required("Language is required"),
    contact_number: Yup.string()
      .matches(phoneRegExp, "Please enter a correct number")
      .min(10, "too short")
      .max(10, "too long")
      .required("Mobile Number is required"),
    phone_code: Yup.string().required("Phone Code is required"),
  });

  useEffect(() => {
    getAllLanguage();
  }, []);

  return !loadingComponent ? (
    <>
      <Helmet>
        <title>Your Profile | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Profile</h3>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <span onClick={() => navigate("/user/dashboard")}>
                      Dashboard
                    </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Profile</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Basic Info</h4>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postData = {
                        name: values.name,
                        email: userDetails?.user?.email,
                        phone: values?.contact_number,
                        phone_code: values?.phone_code || "+1",
                        language_id: values.language_id?.value,
                        referal_code: values?.referral,
                      };
                      await UserUpdateProfileService(userId, postData)
                        .then((res) => {
                          if (res?.data?.data) {
                            SuccessToast(
                              res?.data?.message ||
                                "Profile Updated successfully."
                            );
                            setSubmitting(false);
                            window.location.reload(true);
                          }
                          window.location.reload(true);
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        isSubmitting,
                        values,
                        setFieldValue,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>
                                  Name <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.name
                                      ? "input-error"
                                      : null
                                  }`}
                                  onChange={(newVal) => {
                                    setFieldValue("name", newVal.target.value);
                                  }}
                                  name="name"
                                  placeholder="Enter Name"
                                  value={values?.name}
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>
                                  Email <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  disabled
                                  className={`form-control ${
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="email"
                                  placeholder="Enter email"
                                  value={initialValues?.email}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group remove-inner-input">
                                <label>
                                  Phone Number
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="row">
                                  <div className="col-4 p-0">
                                    <PhoneInput
                                      international
                                      countryCallingCodeEditable={false}
                                      defaultCountry="US"
                                      type="text"
                                      placeholder="Country Code"
                                      onChange={(newVal) => {
                                        setFieldValue("phone_code", newVal);
                                      }}
                                      className={`form-control ${
                                        errors?.phone_code &&
                                        touched?.phone_code
                                          ? "input-error"
                                          : null
                                      }`}
                                      value={values?.phone_code}
                                      name="phone_code"
                                    />
                                  </div>
                                  <div className="col-8">
                                    <Field
                                      type="text"
                                      className={`form-control ${
                                        errors.contact_number &&
                                        touched.contact_number
                                          ? "input-error"
                                          : null
                                      }`}
                                      onChange={(newVal) => {
                                        setFieldValue(
                                          "contact_number",
                                          newVal.target.value
                                        );
                                      }}
                                      name="contact_number"
                                      placeholder="Enter Phone Number"
                                      value={values?.contact_number}
                                    />
                                  </div>
                                </div>
                                <ErrorMessage
                                  name="contact_number"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group remove-inner-input">
                                <label>
                                  Language{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={allLangeage}
                                  onChange={(newVal) => {
                                    setFieldValue("language_id", newVal);
                                  }}
                                  name="language_id"
                                  value={values?.language_id}
                                  placeholder="Select language"
                                />
                              </div>
                            </div>
                            {!referralCode && (
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Referral</label>
                                  <Field
                                    type="text"
                                    className={`form-control ${
                                      errors.name && touched.name
                                        ? "input-error"
                                        : null
                                    }`}
                                    onChange={(newVal) => {
                                      setFieldValue(
                                        "referral",
                                        newVal.target.value
                                      );
                                    }}
                                    name="referral"
                                    placeholder="Enter referral code"
                                    value={values?.referral}
                                  />
                                  <ErrorMessage
                                    name="referral"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Update Profile
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <ChangePassword />
            </div>
          </div>
        </div>
        <UserFooter />
      </div>
    </>
  ) : (
    <Loader loading={loading} />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.reducers?.loading,
    userDetails: state?.reducers?.userDetails,
  };
};

const mapActionsToProps = (actions) => {
  return {};
};

export default connect(mapStateToProps, mapActionsToProps)(Profile);
