import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { UserFooter } from "../Layout";

const Updates = () => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <>
      <Helmet>
        <title>Announcement | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Announcement</h3>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <span onClick={() => navigate("/user/dashboard")}>
                      Dashboard
                    </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Announcement</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="faq-hero">
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <h3>PhrasePulse Beta Updates!</h3>
                  <div className="text">
                    <p>
                      We're constantly working to improve your interpreter
                      practice experience! Here's a sneak peek at some exciting
                      features coming soon.
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2">
                  <div className="faq-img">
                    <img
                      className="img-responsive w-auto"
                      src="/inner-assets/img/user-updates.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <Accordion className="accordion-items" defaultExpanded>
                <AccordionSummary
                  className="title"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Multilingual Support
                </AccordionSummary>
                <AccordionDetails>
                  Currently supports Hindi, with Gujarati coming on 1st April,
                  Nepali coming on April 4th, Punjabi on April 15th, and more
                  languages planned (Urdu and Telugu mentioned).
                </AccordionDetails>
              </Accordion>
              <Accordion className="accordion-items">
                <AccordionSummary
                  className="title"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Progressive Feature Rollout
                </AccordionSummary>
                <AccordionDetails>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      Human Marking starts April 1st.
                    </li>
                    <li className="list-group-item">
                      Comprehensive Human Feedback starts May 1st for Hindi
                      (gradually rolling out for other languages).
                    </li>
                    <li className="list-group-item">
                      AI Assessment starts June 1st for Hindi and Nepali
                      (gradually rolling out for other languages).
                    </li>
                    <li className="list-group-item">
                      Detailed AI Analysis starts July 1st.
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        <UserFooter />
      </div>
    </>
  );
};

export default Updates;
