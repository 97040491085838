import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import Select from "react-select";
import { AdminFooter } from "../../Layout";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import {
  AddProductService,
  GetCategoryListService,
} from "../../../Services/Api/product";
import { adminUploadFile } from "../../../Services/Api/fileUpload";
import AddSection from "./AddSection";

const AddProduct = () => {
  const navigate = useNavigate();
  const [categories, setCategory] = useState([]);
  const [sections, setSections] = useState([
    {
      sectionId: 1,
      file: [],
      options: [
        {
          optionId: 1,
          name: "",
        },
      ],
    },
  ]);

  const CategoryList = async () => {
    await GetCategoryListService()
      .then((res) => {
        if (res?.data?.data?.length) {
          const categoryData = res?.data?.data?.map((category) => {
            return {
              label: `${category?.language?.name} > ${category?.name}`,
              value: category?._id,
            };
          });
          setCategory(categoryData);
        } else {
          setCategory([]);
        }
      })
      .catch((err) => {
        setCategory([]);
      });
  };

  const signInSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    category_id: Yup.object().required("Category is required"),
  });

  const initialValues = {
    name: "",
    category_id: "",
    short_desc: "",
    long_desc: "",
  };

  const handleSection = () => {
    const newSection = {
      sectionId: sections.length + 1,
      file: [],
      options: [
        {
          optionId: 1,
          name: "",
        },
      ],
    };
    setSections((prevSections) => [...prevSections, newSection]);
  };

  const handleRemoveSection = (sectionId) => {
    setSections((prevSections) =>
      prevSections.filter((section) => section.sectionId !== sectionId)
    );
  };

  const handleOption = (sectionId) => {
    setSections((prevSections) => {
      return prevSections.map((section) => {
        if (section.sectionId === sectionId) {
          const newOption = {
            optionId: section.options.length + 1,
            name: "",
          };
          return {
            ...section,
            options: [...section.options, newOption],
          };
        }
        return section;
      });
    });
  };

  const handleRemoveOption = (sectionId, optionId) => {
    setSections((prevSections) => {
      return prevSections.map((section) => {
        if (section.sectionId === sectionId) {
          return {
            ...section,
            options: section.options.filter(
              (option) => option.optionId !== optionId
            ),
          };
        }
        return section;
      });
    });
  };

  const handleAudioFile = (event, sectionId) => {
    const selectedFile = event.target.files[0];
    setSections((prevSections) => {
      return prevSections.map((section) => {
        if (section.sectionId === sectionId) {
          return {
            ...section,
            file: selectedFile,
          };
        }
        return section;
      });
    });
  };

  const handleOptionValue = (value, sectionId, optionId) => {
    const updatedSections = [...sections];
    updatedSections[sectionId - 1].options[optionId - 1].name = value;
    setSections(updatedSections);
  };

  useEffect(() => {
    CategoryList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Add Product | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Add Product</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/product">Products</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Product</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/product" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      let sectionData = [];
                      if (sections?.length) {
                        for (const item of sections) {
                          let filepath = "";
                          try {
                            let fileRes = await adminUploadFile(item?.file);
                            filepath = fileRes?.data?.data?.path || "";
                          } catch (error) {}
                          sectionData.push({
                            ...item,
                            file: filepath,
                          });
                        }
                      }
                      const postData = {
                        name: values.name,
                        short_desc: values?.short_desc,
                        long_desc: values?.long_desc,
                        category_id: values.category_id?.value,
                        sections: sectionData,
                      };
                      await AddProductService(postData)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message ||
                              "Product created successfully."
                          );
                          setSubmitting(false);
                          navigate("/admin/Product");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const {
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        values,
                      } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Name <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.name && touched.name
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="name"
                                  placeholder="Enter name"
                                />
                                <ErrorMessage
                                  name="name"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Category{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Select
                                  options={categories}
                                  onChange={(newVal) => {
                                    setFieldValue("category_id", newVal);
                                  }}
                                  name="category_id"
                                  value={values.category_id}
                                  placeholder="Select Category"
                                />
                                <ErrorMessage
                                  name="category_id"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Short Description</label>
                                <Field
                                  as="textarea"
                                  className={`form-control`}
                                  name={`short_desc`}
                                  placeholder="Enter Short Description"
                                />
                                <ErrorMessage
                                  name={`short_desc`}
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label>Long Description</label>
                                <Field
                                  as="textarea"
                                  className={`form-control`}
                                  name={`long_desc`}
                                  placeholder="Enter Long Description"
                                />
                                <ErrorMessage
                                  name={`long_desc`}
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              {sections?.map((section) => (
                                <AddSection
                                  key={section.sectionId}
                                  sectionCount={sections?.length}
                                  section={section}
                                  handleSection={handleSection}
                                  handleRemoveSection={handleRemoveSection}
                                  handleOption={handleOption}
                                  handleAudioFile={handleAudioFile}
                                  handleOptionValue={handleOptionValue}
                                  handleRemoveOption={handleRemoveOption}
                                  errors={errors}
                                  touched={touched}
                                />
                              ))}
                              <div className="form-group">
                                <div className="add-new-section">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleSection()}
                                  >
                                    Add More Section
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add Product
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
