import axios from "axios";
export const baseURL = process.env.REACT_APP_API_USER_BASE_URL;

const VerifyApi = axios.create({
  baseURL,
});

VerifyApi.interceptors.request.use((req) => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  if (token) {
    req.headers.Authorization = `${token}`;
  }
  return req;
});

VerifyApi.defaults.headers.post["Content-Type"] = "application/json";

VerifyApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.assign("/register");
    }
    return Promise.reject(error);
  }
);

export default VerifyApi;
