import React from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
  const { title, link, count } = props;

  return (
    <div className="card">
      <div className="card-body">
        <div className="dash-widget-header">
          <span className="dash-widget-icon bg-2">
            <i className="fas fa-tachometer-alt" />
          </span>
          <div className="dash-count">
            <div className="dash-title">
              <Link to={link}>{title}</Link>
            </div>
            <div className="dash-counts">
              <div>{count}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
