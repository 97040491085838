import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { UserLoginService, fetchIpAddress } from "../../../Services/Api/auth";
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";
import { Helmet } from "react-helmet-async";
import { Loader } from "../../../Utils/Loader";

const Login = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMyIp, setIsMyIp] = useState("");

  const FetchIpAddress = async (limit = "", page = "") => {
    setIsLoading(true);
    await fetchIpAddress({ limit: limit, page: page })
      .then((res) => {
        setIsLoading(false);
        if (res?.data) {
          setIsMyIp(res?.data?.ip || "");
        } else {
          setIsMyIp("");
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    FetchIpAddress();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>User Login | PhrasePulse</title>
      </Helmet>
      {!isLoading ? (
        <div>
          <div className="main-wrapper login-body">
            <div className="login-wrapper">
              <div className="container">
                <Link to={`/`}>
                  <img
                    className="img-fluid logo-dark mb-2"
                    src="/inner-assets/img/logo.png"
                    alt="Logo"
                  />
                </Link>
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <h1>Login</h1>
                      <p className="account-subtitle">
                        Access to our dashboard
                      </p>
                      <Formik
                        initialValues={{
                          email: "",
                          password: "",
                          ip: isMyIp,
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string()
                            .email()
                            .required("Email is required"),
                          password: Yup.string().required(
                            "Password is required"
                          ),
                        })}
                        validateOnChange
                        onSubmit={async (values, { setSubmitting }) => {
                          await UserLoginService(values)
                            .then((res) => {
                              localStorage.setItem(
                                "userAccessToken",
                                res?.data?.data?.token
                              );
                              SuccessToast(
                                res?.data?.message || "Login successful."
                              );
                              setSubmitting(false);
                              window.location.assign("/user/dashboard");
                            })
                            .catch((err) => {
                              if (
                                typeof err.response.data.message !== "undefined"
                              ) {
                                ErrorToast(
                                  err.response.data.message || "Server Error!!"
                                );
                              } else {
                                ErrorToast(err?.message || "Server Error!!");
                              }
                              setSubmitting(false);
                            });
                        }}
                      >
                        {({
                          errors,
                          touched,
                          isValid,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                        }) => {
                          return (
                            <Form onSubmit={handleSubmit}>
                              <div className="form-group">
                                <label className="form-control-label">
                                  Email <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="email"
                                  onChange={handleChange}
                                  className={`form-control ${
                                    errors.email && touched.email
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="email"
                                  placeholder="Enter email"
                                />
                                <ErrorMessage
                                  name="email"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <label className="form-control-label">
                                  Password{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="pass-group">
                                  <Field
                                    type={isShowPassword ? "text" : "password"}
                                    onChange={handleChange}
                                    className={`form-control ${
                                      errors.password && touched.password
                                        ? "input-error"
                                        : null
                                    }`}
                                    name="password"
                                    placeholder="Enter password"
                                  />
                                  <span
                                    onClick={() =>
                                      setIsShowPassword(!isShowPassword)
                                    }
                                    className={`fas ${
                                      isShowPassword ? "fa-eye-slash" : "fa-eye"
                                    } toggle-password`}
                                  />
                                </div>
                                <ErrorMessage
                                  name="password"
                                  component="span"
                                  className="error"
                                />
                              </div>
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-6">
                                    <div className="custom-control custom-checkbox">
                                      <label
                                        className="custom_check"
                                        htmlFor="cb1"
                                      >
                                        <input
                                          type="checkbox"
                                          name="invoice"
                                          id="cb1"
                                        />
                                        Remember me
                                        <span className="checkmark" />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-6 text-end">
                                    <NavLink to="/forgot-password">
                                      Forgot Password ?
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                              <button
                                type="submit"
                                className={`btn btn-lg btn-block btn-primary w-100 ${
                                  !isValid ? "disabled-btn" : ""
                                }`}
                                disabled={isSubmitting}
                              >
                                Login
                                {isSubmitting && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                              </button>
                              <div className="text-center dont-have register-link-client">
                                Don't have an account?{" "}
                                <Link to="/register">Register Now</Link>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader loading={isLoading} />
      )}
    </>
  );
};

export default Login;
