import axios from 'axios';
export const baseURL = process.env.REACT_APP_API_USER_BASE_URL;

const UserApi = axios.create({
  baseURL,
});

UserApi.interceptors.request.use((req) => {
  const token = localStorage.getItem('userAccessToken');
  if (token) {
    req.headers.Authorization = `${token}`;
  }
  return req;
});

UserApi.defaults.headers.post['Content-Type'] = 'application/json';

UserApi.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('userAccessToken');
    localStorage.removeItem('userData');
    window.location.assign('/user/auth/login');
  }
  return Promise.reject(error);
});

export default UserApi;
