import React, { useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import AddOption from "./AddOption";

const AddSection = (props) => {
  const {
    section,
    sectionCount,
    handleRemoveSection,
    handleOption,
    handleAudioFile,
    handleOptionValue,
    handleRemoveOption,
    errors,
    touched,
  } = props;

  useEffect(() => {}, []);

  return (
    section && (
      <div className="main-section">
        <h4>Section {section?.sectionId}</h4>
        <div className="form-group">
          <label>
            Audio File <span className="text-danger">*</span>
          </label>
          <Field
            type="file"
            multiple
            className={`form-control ${
              errors.audio && touched.audio ? "input-error" : null
            }`}
            name={`audio_${section?.sectionId}`}
            onChange={(event) => handleAudioFile(event, section?.sectionId)}
          />
          <ErrorMessage
            name={`audio_${section?.sectionId}`}
            component="span"
            className="error"
          />
        </div>

        <div className="main-option">
          {section?.options?.map((option) => (
            <AddOption
              key={option.optionId}
              sectionId={section?.sectionId}
              option={option}
              optionCount={section?.options?.length}
              handleOptionValue={handleOptionValue}
              handleRemoveOption={handleRemoveOption}
              errors={errors}
              touched={touched}
            />
          ))}
          <div className="form-group">
            <div className="add-new-option">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleOption(section?.sectionId)}
              >
                Add More Option
              </button>
            </div>
          </div>
        </div>
        {sectionCount > 1 && sectionCount === parseInt(section?.sectionId) && (
          <span
            title="Remove"
            role="button"
            className="d-flex justify-content-end mt-2 text-danger"
            onClick={() => handleRemoveSection(section?.sectionId)}
          >
            <i className="fa fa-trash"></i>
          </span>
        )}
      </div>
    )
  );
};

export default AddSection;
