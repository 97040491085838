import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { SendContactus } from "../../Services/Api/comman";
import { ErrorToast, SuccessToast } from "../../Utils/SweetAlert";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await SendContactus(formData)
      .then((res) => {
        SuccessToast(res?.data?.message || "Details sent successfully.");
        navigate("/");
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });

    setFormData({ name: "", email: "", message: "", subject: "" });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact Us | PhrasePulse</title>
        <meta
          name="description"
          content="Our team is always here to help. Get in touch with us! Reach out to us!"
        />
        <link rel="canonical" href="https://phrasepulse.com/contact" />
      </Helmet>
      <div className="inner-page">
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12} className="mb-5">
              <div className="common-page text-center">
                <h1>Our team is always here to help</h1>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="card">
                <div className="card-header">
                  <h2>Get in touch with us!</h2>
                </div>
                <div className="card-body">
                  <Form onSubmit={handleSubmit} className="contact-us-form">
                    <Form.Group controlId="formName" className="form-group">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>

                    <Form.Group controlId="formEmail" className="form-group">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>

                    <Form.Group controlId="formName" className="form-group">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>

                    <Form.Group controlId="formMessage" className="form-group">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter your message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formMessage" className="form-group">
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="card">
                <div className="card-header">
                  <h2>Reach out to us!</h2>
                </div>
                <div className="card-body">
                  <div className="contact-us-right">
                    <div className="cf-block">
                      <div className="icon">
                        <i className="fas fa-envelope"></i>
                      </div>
                      <div className="text">
                        <span>Email</span>
                        <p>{`support[at]phrasepulse[dot]com`}</p>
                      </div>
                    </div>
                    <div className="cf-block">
                      <div className="icon">
                        <i className="fas fa-map-marker-alt"></i>
                      </div>
                      <div className="text">
                        <span>Address</span>
                        <p>
                          Tulsi Park - 2, <br />
                          Raiya Telephone Exchange,
                          <br /> 150 Feet Ring Road, Rajkot, <br />
                          Gujarat, India - 360 005
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ContactUs;
