import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Rating from "@mui/material/Rating";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { AddFeedback } from "../../../Services/Api/feedback";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { UserFooter } from "../Layout";

const Feedback = () => {
  const navigate = useNavigate();
  const [rating, setRating] = useState(1);

  const signInSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    rating: Yup.number().required("Rating is required"),
    message: Yup.string().required("Message is required"),
  });

  const initialValues = {
    title: "",
    rating: 1,
    message: "",
  };

  const handleChange = (value) => {
    setRating(value);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Helmet>
        <title>Feedback | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Feedback</h3>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <span onClick={() => navigate("/user/dashboard")}>
                      Dashboard
                    </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Feedback</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="faq-hero">
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <h3>Please provide details about your experience</h3>
                  <div className="text">
                    <p>
                      We value your input and strive to continuously improve our
                      service. Your feedback helps us understand what we're
                      doing right and where we can make enhancements. Please
                      take a moment to share your thoughts with us.
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2">
                  <div className="faq-img">
                    <img
                      className="img-responsive w-auto"
                      src="/inner-assets/img/feedback.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <h4 className="card-title">Feedback From</h4>
                </div>
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postData = {
                        title: values.title,
                        rating: rating,
                        message: values.message,
                      };
                      await AddFeedback(postData)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "User created successfully."
                          );
                          setSubmitting(false);
                          window.location.reload(true);
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, isSubmitting } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>
                                  Caption <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.title && touched.title
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="title"
                                  placeholder="Enter Caption"
                                />
                                <ErrorMessage
                                  name="title"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>
                                  Rating <span className="text-danger">*</span>
                                </label>
                                <div className="rating-faq">
                                  <Rating
                                    name="size-large"
                                    defaultValue={rating}
                                    max={5}
                                    size="large"
                                    onChange={(event, value) =>
                                      handleChange(value)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label>Message</label>
                                <Field
                                  as="textarea"
                                  className={`form-control ${
                                    errors.message && touched.message
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="message"
                                  placeholder="Enter any message"
                                />
                                <ErrorMessage
                                  name="message"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-left">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Add Feedback
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserFooter />
      </div>
    </>
  );
};

export default Feedback;
