import React, { useEffect } from "react";
import { Field, ErrorMessage } from "formik";

const AddOption = (props) => {
  const {
    sectionId,
    option,
    optionCount,
    handleOptionValue,
    handleRemoveOption,
  } = props;

  useEffect(() => {}, []);

  return (
    <>
      <div className="form-group">
        <label>Option {option?.optionId}</label>
        <Field
          type="text"
          className={`form-control`}
          name={`option_${sectionId}_${option?.optionId}`}
          placeholder="Enter option text"
          onChange={(event) => {
            handleOptionValue(event.target.value, sectionId, option?.optionId);
          }}
        />
        <ErrorMessage
          name={`option_${sectionId}_${option?.optionId}`}
          component="span"
          className="error"
        />
      </div>
      {optionCount > 1 && optionCount === parseInt(option?.optionId) && (
        <span
          title="Remove"
          role="button"
          className="d-flex justify-content-end mt-2 text-danger"
          onClick={() => handleRemoveOption(sectionId, option?.optionId)}
        >
          <i className="fa fa-trash"></i>
        </span>
      )}
    </>
  );
};

export default AddOption;
