import React, { useEffect, useRef, useState } from "react";
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";

const AdminLayout = () => {
  const [openSidebar, setOpenSidebar] = useState(true);
  const sidebarRef = useRef(null); // used to get sidebar width
  const [isMobile, setMobile] = useState(document.body.clientWidth < 991);
  // use 'init' | 'open' | 'close', that you don't need remember if suer clicked
  const [sidebarStatus, setSidebarStatus] = useState("init");

  useEffect(() => {
    // add listener only once, or many listeners would be created every render
    const mq = window.matchMedia("(max-width: 991px)");
    mq.addListener((res) => {
      setMobile(res.matches);
    });
    return () => mq.removeListener(toggleSidebar);
  }, []);

  const showSidebar =
    sidebarStatus === "open" || (!isMobile && sidebarStatus === "init");

  const toggleSidebar = (open) => {
    setSidebarStatus(open ? "open" : "close");
  };
  return (
    <>
      <div className="header header-one">
        <AdminHeader onClick={toggleSidebar} showSideBar={showSidebar} />
      </div>
      <div className={`sidebar ${showSidebar ? 'opened' : ''}`} id="sidebar" ref={sidebarRef}>
        {showSidebar && (
          <AdminSidebar
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            setSidebarStatus={setSidebarStatus}
            handleonClick={toggleSidebar}
            showSideBar={showSidebar}
            isMobile={isMobile}
          />
        )}
      </div>
    </>
  );
};
export default AdminLayout;
