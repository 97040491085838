import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { Col, Row, Button, Card as BCard } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ProgressBar from "react-bootstrap/ProgressBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FitnessCenterOutlinedIcon from "@mui/icons-material/FitnessCenterOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import { SuccessToast, ErrorToast } from "../../../Utils/SweetAlert";
import { UserFooter } from "../Layout";
import {
  setProductData,
  clearProductData,
} from "../../../Services/Api/actions";
import { GetDashboardProductList } from "../../../Services/Api/comman";
import { GetWordStackFavourite } from "../../../Services/Api/wordStack";
import { Loader } from "../../../Utils/Loader";

const Dashboard = (props) => {
  const { userDetails } = props;
  const userData = userDetails;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [freeProducts, setFreeProducts] = useState([]);
  const [featureProducts, setFeatureProducts] = useState([]);
  const [favouriteWord, setFavouriteWord] = useState([]);
  const [lastExamScore, setLastExamScore] = useState(0);
  const [totalExercises, setTotalExercises] = useState(0);
  const [totalWorldStack, setTotalWorldStack] = useState(0);

  const getDashboardProductList = async () => {
    await GetDashboardProductList().then((res) => {
      if (res?.data?.data) {
        let localFree = res?.data?.data?.free_products || [];
        let localFeature = res?.data?.data?.feature_products || [];
        let localFavourite = res?.data?.data?.favourite_word_stacks || [];
        // console.log("localFeature = ", localFeature);
        // console.log("localFree = ", localFree);
        // console.log("localFavourite = ", localFavourite);

        setFreeProducts(localFree);
        setFeatureProducts(localFeature);
        setFavouriteWord(localFavourite);
        setLastExamScore(res?.data?.data?.last_exam_score);
        setTotalExercises(res?.data?.data?.total_exercises);
        setTotalWorldStack(res?.data?.data?.total_world_stack);
      } else {
        setFreeProducts([]);
        setFeatureProducts([]);
        setFavouriteWord([]);
        setLastExamScore(0);
        setTotalExercises(0);
        setTotalWorldStack(0);
      }
    });
  };

  const getWordStackFavourite = async (id) => {
    setIsLoading(true);
    await GetWordStackFavourite(id)
      .then((res) => {
        setIsLoading(false);
        SuccessToast(res?.data?.message || "Updated");
        window.location.reload();
      })
      .catch((err) => {
        setIsLoading(false);
        if (typeof err?.response?.data?.message !== "undefined") {
          ErrorToast(err?.response?.data?.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
        window.location.reload();
      });
  };

  const handleProductClick = (product_id) => {
    if (product_id) {
      dispatch(setProductData(product_id));
      navigate("/user/start-exercise");
    } else {
      ErrorToast("Product is empty.");
      dispatch(clearProductData(null));
    }
  };

  const handleSpeak = (text) => {
    const value = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(value);
  };

  const handleTranslateSpeak = (lang, text) => {
    const value = new SpeechSynthesisUtterance(text);
    if (lang === "Hindi") {
      value.lang = "hi";
    } else if (lang === "Hindi") {
      value.lang = "gu";
    } else {
      value.lang = "gu";
    }
    window.speechSynthesis.speak(value);
  };

  useEffect(() => {
    getDashboardProductList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Welcome back,</h3>
                <Breadcrumb>
                  <Breadcrumb.Item active className="text-capitalize">
                    {userData?.user?.name}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>

          <Row>
            <Col xl={8} sm={12} xs={12}>
              <div className="dashboard-welcome-banner">
                <Row>
                  <Col xl={8} sm={8} xs={12}>
                    <div className="welcome-text">
                      <h2>Coming Soon to PhrasePulse</h2>
                      <p>
                        Launching Gujarati Dialogues and Vocabulary for
                        practice! Available for users to engage and improve
                        their skills starting April 1st, 2024.
                      </p>
                      <Button
                        variant="light"
                        size="lg"
                        className="btnstl"
                        onClick={() => {
                          navigate("/user/updates");
                        }}
                      >
                        Beta Updates!
                      </Button>
                    </div>
                  </Col>
                  <Col xl={4} sm={4} xs={12} className="mt-2">
                    <img
                      src={`/inner-assets/img/preparation-image.png`}
                      alt=""
                    />
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xl={4} sm={12} xs={12}>
              <Row>
                <Col lg={12} sm={12} xs={12}>
                  <div className="card dashoboar-count-card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col mt-0">
                          <h5 className="card-title mb-2">Total Exercise</h5>
                          <h2 className="count">{totalExercises || 0}</h2>
                        </div>
                        <div className="col-auto">
                          <div className="stat text-primary">
                            <FitnessCenterOutlinedIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg={12} sm={6} xs={12}>
                  <div className="card dashoboar-count-card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col mt-0">
                          <h5 className="card-title mb-2">Last Exam Score</h5>
                          <h2 className="count">{lastExamScore}%</h2>
                        </div>
                        <div className="col-auto">
                          <div className="stat text-primary">
                            <FileDownloadOutlinedIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg={12} sm={6} xs={12}>
                  <div className="card dashoboar-count-card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col mt-0">
                          <h5 className="card-title mb-2">Word Pulse</h5>
                          <h2 className="count">{totalWorldStack || 0}</h2>
                        </div>
                        <div className="col-auto">
                          <div className="stat text-primary">
                            <FitnessCenterOutlinedIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg={12} sm={6} xs={12}>
                  <div className="card dashoboar-count-card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col mt-0">
                          <h5 className="card-title mb-2">Your language</h5>
                          <h2 className="count">
                            {userData?.user?.language?.name}
                          </h2>
                        </div>
                        <div className="col-auto">
                          <div className="stat text-primary">
                            <FitnessCenterOutlinedIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            {false && (
              <Col lg={12} sm={12} xs={12}>
                <h5 className="mb-3">Study Status</h5>
                <Row>
                  <Col lg={4} sm={4} xs={12}>
                    <div className="card progress-card">
                      <div className="card-body">
                        <h3 className="title">Grammar & Syntax</h3>
                        <span className="percentage">60%</span>
                        <ProgressBar variant="info" now={60} />
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} sm={4} xs={12}>
                    <div className="card progress-card">
                      <div className="card-body">
                        <h3 className="title">Overall Accuracy</h3>
                        <span className="percentage">75%</span>
                        <ProgressBar variant="warning" now={75} />
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} sm={4} xs={12}>
                    <div className="card progress-card">
                      <div className="card-body">
                        <h3 className="title">Overall Fluency</h3>
                        <span className="percentage">95%</span>
                        <ProgressBar variant="success" now={95} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>

          <Row>
            {featureProducts?.length > 0 && (
              <Col lg={12} sm={12} xs={12}>
                <Row className="justify-content-start">
                  <h5 className="mb-3">Feature Dialogue Exercise</h5>
                  <Row>
                    {featureProducts?.map((product) => (
                      <Col lg={4} sm={6} xs={12} key={product?._id}>
                        {!isLoading ? (
                          <div className="card feature-exercise-card">
                            <Card>
                              <CardContent>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                  className="sub-title d-flex justify-content-between"
                                >
                                  {product?.category?.name}
                                  <span className="text-end text-primary small">
                                    {product?.category?.credit} PPC
                                  </span>
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                  className="title"
                                >
                                  {product?.name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {product?.short_desc ||
                                    `It looks like you're using React code to map over an array of products and render them as cards. However, there seems to be a small issue with your code.`}
                                </Typography>
                                <Button
                                  variant="primary"
                                  size="lg"
                                  className="btnstl mt-3"
                                  onClick={() =>
                                    handleProductClick(product?._id)
                                  }
                                >
                                  Practice Now
                                </Button>
                              </CardContent>
                            </Card>
                          </div>
                        ) : (
                          <Loader loading={isLoading} />
                        )}
                      </Col>
                    ))}
                  </Row>
                </Row>
              </Col>
            )}
            {freeProducts?.length > 0 && (
              <Col lg={12} sm={12} xs={12}>
                <div className="justify-content-start">
                  <h5 className="mb-3">Free Dialogue Practice</h5>
                  <Row>
                    {freeProducts?.map((product) => (
                      <Col lg={4} sm={6} xs={12} key={product?._id}>
                        <div className="card feature-exercise-card">
                          <Card>
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="sub-title d-flex justify-content-between"
                              >
                                {product?.category?.name}
                                <span className="text-end text-primary small">
                                  Free
                                </span>
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="title"
                              >
                                {product?.name}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {product?.short_desc ||
                                  `It looks like you're using React code to map over an array of products and render them as cards. However, there seems to be a small issue with your code.`}
                              </Typography>
                              <Button
                                variant="primary"
                                size="lg"
                                className="btnstl mt-3"
                                onClick={() => handleProductClick(product?._id)}
                              >
                                Practice Now
                              </Button>
                            </CardContent>
                          </Card>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
            )}

            <Col lg={6} sm={12} xs={12}>
              <BCard>
                <BCard.Body>
                  <BCard.Title>
                    <h5 className="mb-4">Latest Update</h5>
                  </BCard.Title>
                  <BCard.Text>
                    We're constantly working to improve your interpreter
                    practice experience! Here's a sneak peek at some exciting
                    features coming soon.
                    <br />
                    <br />
                    Currently supports Hindi, with Gujarati coming on 1st April,
                    Nepali coming on April 4th, Punjabi on April 15th, and more
                    languages planned (Urdu and Telugu mentioned).
                  </BCard.Text>
                  <BCard.Link
                    href={`/user/updates`}
                    className="text-end align-end text-decoration-underline link"
                  >
                    Read more...
                  </BCard.Link>
                </BCard.Body>
              </BCard>
            </Col>

            {favouriteWord?.length > 0 ? (
              <Col lg={6} sm={12} xs={12}>
                <BCard>
                  <BCard.Body>
                    <BCard.Title>
                      <h5 className="mb-4">Your Favorite Word Pulse</h5>
                    </BCard.Title>
                    <BCard.Body className="p-0">
                      <div className="table-responsive custom-pagination">
                        <table className="table table-center table<img<image-hover datatable">
                          <thead className="thead-light">
                            <tr>
                              <th>Original</th>
                              <th>Play</th>
                              <th>Translate</th>
                              <th>Play</th>
                              <th>Favorite</th>
                            </tr>
                          </thead>
                          <tbody>
                            {favouriteWord?.map((word, index) => (
                              <tr key={index}>
                                <td>{word?.first_language || ""}</td>
                                <td>
                                  <Button
                                    variant="outline-primary"
                                    className="play-btn p-0"
                                    onClick={() =>
                                      handleSpeak(word?.first_language)
                                    }
                                  >
                                    <PlayArrowOutlinedIcon />
                                  </Button>
                                </td>
                                <td>{word?.second_language || ""}</td>
                                <td>
                                  <Button
                                    variant="outline-primary"
                                    className="play-btn p-0"
                                    onClick={() =>
                                      handleTranslateSpeak(
                                        word?.language?.name,
                                        word?.second_language
                                      )
                                    }
                                  >
                                    <PlayArrowOutlinedIcon />
                                  </Button>
                                </td>
                                <td>
                                  <Button
                                    variant="outline-primary"
                                    onClick={() =>
                                      getWordStackFavourite(word?._id)
                                    }
                                  >
                                    <FavoriteIcon />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={5} className="text-left">
                                <Link
                                  to={`/user/stock`}
                                  className="text-decoration-underline"
                                >
                                  More words...
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </BCard.Body>
                  </BCard.Body>
                </BCard>
              </Col>
            ) : (
              <Col lg={6} sm={12} xs={12}>
                <BCard>
                  <BCard.Body className="p-0">
                    <img src={`/inner-assets/img/user-updates.jpg`} alt="" />
                  </BCard.Body>
                </BCard>
              </Col>
            )}
          </Row>
        </div>
        <UserFooter />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.reducers?.loading,
    userDetails: state?.reducers?.userDetails,
  };
};

const mapActionsToProps = (actions) => {
  return {};
};

export default connect(mapStateToProps, mapActionsToProps)(Dashboard);
