import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { AdminFooter } from "../../Layout";
import {
  GetSupportByIdService,
  ReplySupportService,
} from "../../../Services/Api/support";

const ReplySupport = () => {
  const stateData = useLocation();
  const { id } = stateData?.state || "";
  const [userReplyId, setUserReplyId] = useState("");
  const [userSupportView, setUserSupportView] = useState({});

  const signInSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Mesage Word is required"),
  });

  const initialValues = {
    subject: "",
    message: "",
    support_id: userReplyId,
  };

  const getSupportById = async (id) => {
    await GetSupportByIdService(id)
      .then((res) => {
        if (res?.data?.data) {
          setUserSupportView(res?.data?.data);
          setUserReplyId(res?.data?.data?._id);
        } else {
          setUserReplyId("");
          setUserSupportView({});
        }
      })
      .catch((err) => {
        setUserReplyId("");
        setUserSupportView({});
      });
  };

  useEffect(() => {
    if (id) {
      getSupportById(id);
    }
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Reply Support | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Reply Support</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/support">Support</Link>
                  </li>
                  <li className="breadcrumb-item active">Reply Support</li>
                </ul>
              </div>
              <div className="col-auto">
                <Link to="/admin/support" className="btn btn-primary me-1">
                  <i className="fas fa-angle-double-left" /> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Basic Info</h4>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    validateOnChange
                    onSubmit={async (values, { setSubmitting }) => {
                      const postData = {
                        support_id: userReplyId,
                        subject: values.subject,
                        message: values.message,
                      };
                      await ReplySupportService(postData)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message || "Replyed successfully."
                          );
                          setSubmitting(false);
                          window.location.reload(true);
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const { errors, touched, isSubmitting } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="clearfix"></div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>User Subject:</label>
                                <p>{userSupportView?.subject}</p>
                              </div>
                              <div className="form-group">
                                <label>User Message:</label>
                                <p>{userSupportView?.message}</p>
                              </div>
                              {userSupportView?.attachments?.[0]?.file_url && (
                                <div className="form-group">
                                  <label>User Image:</label>
                                  <img
                                    src={
                                      userSupportView?.attachments?.[0]
                                        ?.file_url
                                    }
                                    alt=""
                                    className="img-thumbnail me-2"
                                    style={{ maxWidth: "200px" }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-md-12">
                              <h3>Reply: </h3>
                              <div className="form-group">
                                <label>
                                  Subject <span className="text-danger">*</span>
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.subject && touched.subject
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="subject"
                                  placeholder="Enter Subject"
                                />
                                <ErrorMessage
                                  name="subject"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>
                                  Message <span className="text-danger">*</span>
                                </label>
                                <Field
                                  as="textarea"
                                  className={`form-control ${
                                    errors.message && touched.message
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="message"
                                  placeholder="Enter Message"
                                />
                                <ErrorMessage
                                  name="message"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="text-end mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btnstl"
                              disabled={isSubmitting}
                            >
                              Reply
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReplySupport;
