import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import { Loader } from "../../../Utils/Loader";
import { userUploadFile } from "../../../Services/Api/userFileUpload";
import { AddExerciseService } from "../../../Services/Api/exercise";
// import MicIcon from "@mui/icons-material/Mic";
import { useWavesurfer } from "@wavesurfer/react";
import Timeline from "../../../../node_modules/wavesurfer.js/dist/plugins/timeline.esm.js";
import { AudioRecorder } from "react-audio-voice-recorder";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import MicrophoneAccess from "../../../Utils/MicrophoneAccess.jsx";

const StartTest = (props) => {
  const {
    selectedProduct,
    sections,
    audioUrls,
    exerciseId,
    attemptSections,
    currentSection,
    setCurrentSection,
    handleAddSectionExersice,
    ConfirmationCancelExercise,
  } = props;

  const navigate = useNavigate();
  const [hasMicAccess, setHasMicAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [nextAudioBtn, setNextAudioBtn] = useState(false);
  const [finishExerciseBtn, setFinishExerciseBtn] = useState(false);
  const [audioOn, setAudioOn] = useState(false);
  const [playPauseBtn, setPlayPauseBtn] = useState(true);
  const [prepareSections, setPrepareSections] = useState({});
  const [urlIndex, setUrlIndex] = useState(currentSection - 1);
  const totalSections = sections?.length;

  const formatTime = (seconds) =>
    [seconds / 60, seconds % 60]
      .map((v) => `0${Math.floor(v)}`.slice(-2))
      .join(":");
  const containerRef = useRef(null);

  const {
    wavesurfer,
    isPlaying,
    currentTime,
    audioTotalTime = wavesurfer?.getDuration(),
  } = useWavesurfer({
    container: containerRef,
    height: 100,
    waveColor: "rgb(200, 0, 200)",
    progressColor: "rgb(100, 0, 100)",
    url: audioUrls[urlIndex],
    plugins: useMemo(() => [Timeline.create()], []),
  });

  const onUrlChange = useCallback(
    (localCurrentSection) => {
      if (localCurrentSection >= totalSections) {
        // All Section is finish then called (When more then one sections)
        setFinishExerciseBtn(true);
        setNextAudioBtn(false);
        setAudioOn(false);
        setPlayPauseBtn(false);
      } else {
        setPlayPauseBtn(true);
        setNextAudioBtn(false);
        setUrlIndex(localCurrentSection);
        setCurrentSection(localCurrentSection + 1);
      }
    },
    [totalSections, setCurrentSection]
  );

  const onPlayPause = useCallback(() => {
    if (wavesurfer) {
      wavesurfer.playPause();
      wavesurfer.on("finish", () => {
        setAudioOn(true);
      });
    }
  }, [wavesurfer]);

  const addAudioElement = async (recordedBlob) => {
    setIsLoading(true);
    const file = new File([recordedBlob], "recorder.mp3", {
      type: recordedBlob.type,
    });

    let filepath = "";
    try {
      let fileRes = await userUploadFile(file);
      filepath = fileRes?.data?.data?.path || "";
    } catch (error) {}
    if (filepath) {
      const selectedSection = sections
        .map((sectionAudioObj, key) => {
          return (
            key === currentSection - 1 && {
              section_id: sectionAudioObj._id,
              user_file: filepath,
              admin_file: sectionAudioObj.file,
            }
          );
        })
        .filter(Boolean);
      setPrepareSections(selectedSection);

      if (currentSection >= totalSections) {
        // All Section is finish then called (When only one section)
        showFinishButton();
      } else {
        // Add one By One Section Going to Add in API prepare data
        const localPrepareSections = {
          exercise: exerciseId,
          section: selectedSection,
        };
        // console.log("localPrepareSections = ", localPrepareSections);
        handleAddSectionExersice(localPrepareSections);

        setUrlIndex(currentSection - 1);
        setNextAudioBtn(true);
        setAudioOn(false);
        setPlayPauseBtn(false);
      }
    }
    setIsLoading(false);
  };

  const allowPermision = (exception) => {
    ErrorToast(exception || "Allow Mic Permission Error!!", 6000);
  };

  const showFinishButton = () => {
    setSubmitting(false);
    setFinishExerciseBtn(true);
    setNextAudioBtn(false);
    setAudioOn(false);
    setPlayPauseBtn(false);
  };

  const handleSubmitExersice = async () => {
    setIsLoading(true);
    setSubmitting(true);
    const postData = {
      exercise: exerciseId,
      isCompleted: true,
      section: prepareSections?.length ? prepareSections[0] : {},
    };
    await AddExerciseService(postData)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          SuccessToast(
            "Congratulations! You've Completed the Practice Dialogue"
          );
        } else {
          ErrorToast("Somthing went wrong. Server Error!!");
        }
        setSubmitting(false);
        navigate("/user/exercises");
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
        setSubmitting(false);
      });
  };

  useEffect(() => {
    let attemptSectionsCount = attemptSections?.length || 0;
    if (attemptSectionsCount === totalSections) {
      showFinishButton();
    }
  }, [attemptSections, totalSections]);

  return (
    <>
      <Helmet>
        <title>Start Exercise | PhrasePulse</title>
      </Helmet>
      <div className="card-header">
        <h4 className="card-title">{selectedProduct?.name}</h4>
      </div>
      <div className="card-body">
        <div className="d-flex align-items-center flex-wrap mb-4">
          <h6 className="card-title mb-3">
            Start Exercise {currentSection} out of {totalSections}
          </h6>
          <button
            className="btn btn-danger btnstl ms-auto"
            onClick={() => ConfirmationCancelExercise(exerciseId)}
          >
            <StopCircleOutlinedIcon className="me-1" />
            Stop Practice
          </button>
        </div>
        <div className="row">
          {sections.length > 0 && (
            <div className="main-sections">
              <div className="form-group">
                <div className="mb-4 recorder" ref={containerRef} />
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">
                    {currentTime ? formatTime(currentTime) : "00:00"}
                  </div>
                  <div className="fw-bold">
                    {audioTotalTime ? formatTime(audioTotalTime) : "00:00"}
                  </div>
                </div>

                {playPauseBtn && (
                  <div className="row m-2 d-flex justify-content-between align-items-center border-top pt-3 pb-3 border-bottom">
                    <div className="col-lg-6 col-mg-6 col-sm-6">
                      <div className="d-flex justify-content-center align-items-center text-center">
                        <div
                          className={`text-primary cursor-pointer`}
                          onClick={onPlayPause}
                        >
                          {isPlaying ? (
                            <PauseCircleIcon
                              className="me-1"
                              style={{ fontSize: "52px" }}
                            />
                          ) : (
                            <PlayCircleIcon
                              className="me-1"
                              style={{ fontSize: "52px" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {!hasMicAccess && (
                      <MicrophoneAccess
                        hasMicAccess={hasMicAccess}
                        setHasMicAccess={setHasMicAccess}
                      />
                    )}
                    {!isLoading ? (
                      audioOn && (
                        <div className="col-lg-6 col-mg-6 col-sm-6">
                          <div className="d-flex flex-column justify-content-center align-items-center text-center">
                            <AudioRecorder
                              onNotAllowedOrFound={allowPermision}
                              onRecordingComplete={addAudioElement}
                              audioTrackConstraints={{
                                noiseSuppression: true,
                                echoCancellation: true,
                              }}
                              classes={{
                                AudioRecorderClass: "cus-audio-recorder",
                                AudioRecorderDiscardClass: "cus-audio-discard",
                                AudioRecorderStartSaveClass: "cus-audio-save",
                              }}
                              showVisualizer={true}
                              downloadOnSavePress={false}
                            />
                            <span className="text-danger">
                              Speech time: 2 minutes max.
                            </span>
                          </div>
                        </div>
                      )
                    ) : (
                      <div className="col-lg-6 col-mg-6 col-sm-6">
                        <Loader loading={isLoading} />
                      </div>
                    )}
                  </div>
                )}
              </div>

              {nextAudioBtn && (
                <div className="text-start mt-4 float-end">
                  <button
                    className="btn btn-primary btnstl"
                    type="button"
                    onClick={() => onUrlChange(currentSection)}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        {finishExerciseBtn && (
          <div className="row">
            <div className="text-end mt-4">
              <button
                type="submit"
                className="btn btn-primary btnstl"
                disabled={isSubmitting}
                onClick={() => handleSubmitExersice()}
              >
                Submit Exercise
                {isSubmitting && <i className="fas fa-spinner fa-spin"></i>}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StartTest;
