import UserApi from "../../Utils/UserAxios";

/***************** User API **********************/

export const GetNotificationPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await UserApi.get(
          `/notification/paginate?per_page=${limit}&page=${page}`
        )
      );
    } catch (error) {
      reject(error);
    }
  });
};
