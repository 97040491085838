import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../Components/User/Auth/Login";
import UserGuestRoute from "./UserGuestRoute";

const AuthUserRoutes = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<UserGuestRoute Component={Login} />} />
        <Route
          exact
          path="/login"
          element={<UserGuestRoute Component={Login} />}
        />
      </Routes>
    </>
  );
};
export default AuthUserRoutes;
