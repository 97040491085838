import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import { Pagination } from "../../../Utils";
import { AdminFooter } from "../../Layout";
import { GetExercisePaginateService } from "../../../Services/Api/exercise";

const Exercise = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [exerciseListPagination, setexerciseListPagination] = useState([]);
  const navigate = useNavigate();

  const GetExerciseList = async (limit = "", page = "") => {
    setIsLoading(true);
    await GetExercisePaginateService({ limit: limit, page: page })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setexerciseListPagination(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setexerciseListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setexerciseListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    // await GetExerciseList(limit, pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    // await GetExerciseList(parseInt(event.target.value, 10), 0);
  };

  useEffect(() => {
    GetExerciseList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>Exercise | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Exercises</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>Start Date</th>
                          <th>Exercise Name</th>
                          <th>Language</th>
                          <th>Category</th>
                          <th>User</th>
                          <th>Credit Used</th>
                          <th>Status</th>
                          <th>Marks</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {exerciseListPagination.map((exercise, index) => (
                              <tr key={index}>
                                <td>
                                  {moment(exercise?.createdOn).format(
                                    "DD-MM-YYYY hh:mm A"
                                  ) || ""}
                                </td>
                                <td>{exercise?.product?.name || ""}</td>
                                <td>{exercise?.language?.name || ""}</td>
                                <td>{exercise?.category?.name || ""}</td>
                                <td>
                                  {exercise?.user?.name || ""} -{" "}
                                  {exercise?.user?.email || ""}
                                </td>
                                <td>{exercise?.credit_used || 0}</td>
                                <td>
                                  {exercise?.status === 2
                                    ? "Completed"
                                    : "Running"}
                                </td>
                                <td>{exercise?.marks || "-"}</td>
                                <td>
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={() =>
                                      navigate("/admin/edit-exercise", {
                                        state: { id: exercise?._id },
                                      })
                                    }
                                  >
                                    View
                                  </button>
                                </td>
                              </tr>
                            ))}
                            {exerciseListPagination.length === 0 && (
                              <tr>
                                <td colSpan={9}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={9}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={9}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default Exercise;
