import React from "react";
import { NavLink, Link } from "react-router-dom";
import OtherHousesOutlinedIcon from "@mui/icons-material/OtherHousesOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import StorageIcon from "@mui/icons-material/Storage";

const UserSidebar = ({
  openSidebar,
  isMobile,
  setSidebarStatus,
  sidebarDesktopToggel,
}) => {
  const menuclick = () => {
    isMobile ? setSidebarStatus("close") : setSidebarStatus("open");
  };
  return (
    <div
      className="sidebar-inner"
      id="sidebar-inner"
      style={
        openSidebar === true ? { marginLeft: "0px" } : { marginLeft: "-225px" }
      }
    >
      <div className="sidebar-inner slimscroll">
        <div
          id="sidebar-menu"
          className={`${
            sidebarDesktopToggel
              ? "sidebar-menu sidebar-menu-desktop-toggel"
              : "sidebar-menu"
          }`}
        >
          <div className="logo">
            <Link to="/user/dashboard" className="logo-small">
              {!sidebarDesktopToggel ? (
                !isMobile ? (
                  <img src="/inner-assets/img/logo-admin.png" alt="Logo" />
                ) : (
                  <img src="/inner-assets/img/favicon.png" alt="Logo" />
                )
              ) : (
                <img src="/inner-assets/img/favicon.png" alt="Logo" />
              )}
            </Link>
            {isMobile && (
              <Link
                to="#"
                className="siderbar-close"
                onClick={() => setSidebarStatus("close")}
              >
                <CloseOutlinedIcon />
              </Link>
            )}
          </div>
          <ul>
            <li onClick={() => menuclick("dashboard")}>
              <NavLink to="/user/dashboard">
                <span className="icon">
                  <OtherHousesOutlinedIcon />
                </span>
                {!sidebarDesktopToggel && <span>Dashboard</span>}
              </NavLink>
            </li>
            <li onClick={() => menuclick("profile")}>
              <NavLink to="/user/profile">
                <span className="icon">
                  <PersonOutlineOutlinedIcon />
                </span>
                {!sidebarDesktopToggel && <span>Profile</span>}
              </NavLink>
            </li>
            <li onClick={() => menuclick("credit")}>
              <NavLink to="/user/credit">
                <span className="icon">
                  <PaymentsOutlinedIcon />
                </span>
                {!sidebarDesktopToggel && <span>Credit</span>}
              </NavLink>
            </li>
            <li onClick={() => menuclick("start-exercise")}>
              <NavLink to="/user/start-exercise">
                <span className="icon">
                  <SettingsVoiceIcon />
                </span>
                {!sidebarDesktopToggel && <span>Dialogue Corner</span>}
              </NavLink>
            </li>
            <li onClick={() => menuclick("exercises")}>
              <NavLink to="/user/exercises">
                <span className="icon">
                  <StorageIcon />
                </span>
                {!sidebarDesktopToggel && <span>Attempt</span>}
              </NavLink>
            </li>
            <li onClick={() => menuclick("word-stock")}>
              <NavLink to="/user/stock">
                <span className="icon">
                  <InventoryOutlinedIcon />
                </span>
                {!sidebarDesktopToggel && <span>Word Pulse</span>}
              </NavLink>
            </li>
            <li onClick={() => menuclick("faq")}>
              <NavLink to="/user/faq">
                <span className="icon">
                  <QuizOutlinedIcon />
                </span>
                {!sidebarDesktopToggel && <span>Faq's</span>}
              </NavLink>
            </li>
            <li onClick={() => menuclick("feedback")}>
              <NavLink to="/user/feedback">
                <span className="icon">
                  <ChatOutlinedIcon />
                </span>
                {!sidebarDesktopToggel && <span>Feedback</span>}
              </NavLink>
            </li>
            <li onClick={() => menuclick("profile")}>
              <NavLink to="/user/updates">
                <span className="icon">
                  <AnnouncementIcon />
                </span>
                {!sidebarDesktopToggel && <span>Updates</span>}
              </NavLink>
            </li>
            <li onClick={() => menuclick("support")}>
              <NavLink to="/user/support">
                <span className="icon">
                  <SupportAgentOutlinedIcon />
                </span>
                {!sidebarDesktopToggel && <span>Support</span>}
              </NavLink>
            </li>
            <li onClick={() => menuclick("logout")}>
              <NavLink to="/user/logout">
                <span className="icon">
                  <LogoutOutlinedIcon />
                </span>
                {!sidebarDesktopToggel && <span>Logout</span>}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default UserSidebar;
