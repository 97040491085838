import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

const AdminHeader = (props) => {
  const adminData = JSON.parse(localStorage?.getItem("adminData"));

  useEffect(() => {}, []);

  return (
    <div className="d-flex align-items-center w-100">
      <Link to="#" id="toggle_btn">
        <i className="fas fa-bars"></i>
      </Link>
      <Link
        to="#"
        className="mobile_btn"
        id="mobile_btn"
        onClick={() => props.onClick(!props.showSideBar)}
      >
        <MenuOutlinedIcon />
      </Link>
      <ul className="nav nav-tabs user-menu">
        <li className="nav-item dropdown"></li>
        <li className="nav-item main-drop">
          <Link to="/admin/profile" className="nav-link">
            <span className="user-img">
              <img
                src={
                  adminData.profile_picture ||
                  "/inner-assets/img/default_image.jpg"
                }
                alt="imge"
              />
              <span className="status online"></span>
            </span>
            <span>{adminData?.user?.email}</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default AdminHeader;
