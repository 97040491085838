import React from "react";
import { Navigate } from "react-router-dom";

const GuestRoute = ({ Component, updateHeaderFun }) => {
  const loginAdmin = localStorage.getItem("accessToken");

  let isLogin = false;
  if (loginAdmin !== null) {
    isLogin = true;
  }
  return isLogin ? (
    <Navigate to="/admin/dashboard" />
  ) : (
    <Component
      updateHeaderFun={
        typeof updateHeaderFun !== "undefined" ? updateHeaderFun : ""
      }
    />
  );
};
export default GuestRoute;
