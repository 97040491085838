import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const CookiesConsent = () => {
  const [consent, setConsent] = useState(false);

  const handleConsent = () => {
    setConsent(true);
    localStorage.setItem("cookiesConsent", "true");
  };

  const checkConsent = () => {
    const cookiesConsent = localStorage.getItem("cookiesConsent");
    if (cookiesConsent === "true") {
      setConsent(true);
    }
  };

  useEffect(() => {
    checkConsent();
  }, []);

  return (
    !consent && (
      <div className="cookies-consent">
        <p>
          We use cookies to improve user experience. By using our website, you
          consent to all cookies in accordance with our{" "}
          <Link to={`/privacy-policy`}>Privacy Policy</Link>.
        </p>
        <button onClick={handleConsent}>Accept</button>
      </div>
    )
  );
};

export default CookiesConsent;
