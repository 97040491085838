import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VerifyApi from "./AxiosVerifyUser";
import { ErrorToast, SuccessToast } from "./SweetAlert";

const VerifyUser = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const VerifyUserService = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await VerifyApi.post(`/verify_email`));
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    const getVerifyUserService = async () => {
      setIsLoading(true);
      await VerifyUserService()
        .then((res) => {
          if (res?.data?.status) {
            setIsLoading(false);
            SuccessToast(res?.data?.message || "User Approved!!");
            navigate("/user/auth/login");
          } else {
            ErrorToast(res?.data?.message || "Link Expired!!");
            navigate("/register");
          }
        })
        .catch((err) => {
          if (typeof err.response.data.message !== "undefined") {
            ErrorToast(err.response.data.message || "Server Error!!");
          } else {
            ErrorToast(err?.message || "Server Error!!");
          }
          navigate("/register");
        });
    };
    getVerifyUserService();
  }, [navigate]);

  return (
    <>
      {isLoading && (
        <img className="mx-auto d-block" alt="Loding..." src="/loading.gif" />
      )}
    </>
  );
};

export default VerifyUser;
