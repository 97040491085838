import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Dropdown } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

const Footer = ({ selectedCurrency, handleCurrencySelect }) => {
  return (
    <div className="footer">
      {
        <Container>
          <Row className="align-items-center">
            <Col lg={4}>
              <p>
                {`Copyright © ${new Date().getFullYear()} PhrasePulse. All rights
                reserved.`}
              </p>
            </Col>
            <Col
              lg={8}
              className="col d-flex align-self-center justify-content-lg-end justify-content-center align-content-end"
            >
              <ListGroup horizontal>
                <ListGroup.Item>
                  <Link to={`/privacy-policy`}>Privacy Policy</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link to={`/refund-policy`}>Refund Policy</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link to={`/term-and-condition`}>Term and Condition</Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Dropdown className="currency-dropdown">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn-sm currency-btn"
                    >
                      {selectedCurrency || "USD"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleCurrencySelect("USD")}
                      >
                        USD
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleCurrencySelect("INR")}
                      >
                        INR
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      }
    </div>
  );
};

export default Footer;
