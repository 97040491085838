import Api from "../../Utils/Axios";
import UserApi from "../../Utils/UserAxios";

/***************** Admin API **********************/

export const AddWordStackService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/word_stack/store", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetWordStackPaginateService = async ({
  limit,
  page,
  language_id,
  category_id,
  searchText,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const languageId =
        language_id !== null ? `&language_id=${language_id}` : ``;

      const categoryId =
        category_id !== null ? `&category_id=${category_id}` : ``;

      let search_text = searchText !== null ? `&search=${searchText}` : ``;

      resolve(
        await Api.get(
          `/word_stack/paginate?per_page=${limit}&page=${page}${languageId}${categoryId}${search_text}`
        )
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const DeleteWordStackByIdService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.delete(`/word_stack/delete/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const ActiveInactiveWordStackService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/word_stack/change-status/${id}`, {}));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetSearchWordStackService = async (search) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/word_stack/autocomplete?search=${search}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetWordStackByIdService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/word_stack/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const EditWordStackService = async (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post(`/word_stack/update/${id}`, data));
    } catch (error) {
      reject(error);
    }
  });
};

/***************** User API **********************/

export const GetAllWordStack = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/word_stack/list`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetAllWordStackCategory = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/word_stack/category-list`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetWordStackFavourite = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post(`/word_stack/favourite/${id}`, {}));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetWordStackPaginate = async ({
  limit,
  page,
  language_id,
  searchText,
  categoryId,
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const languageId =
        language_id !== null ? `&language_id=${language_id}` : ``;

      const category_id =
        categoryId !== null ? `&category_id=${categoryId}` : ``;

      let search_text = searchText !== null ? `&search=${searchText}` : ``;
      resolve(
        await UserApi.get(
          `/word_stack/paginate?per_page=${limit}&page=${page}${languageId}${search_text}${category_id}`
        )
      );
    } catch (error) {
      reject(error);
    }
  });
};
