import React from "react";
// import Switch from "@mui/material/Switch";
// import FormControlLabel from "@mui/material/FormControlLabel";
import { Button } from "react-bootstrap";

const SelectCategory = (props) => {
  const {
    randomProduct,
    // setRandomProduct,
    category,
    initialValues,
    setInitialValues,
    allCategory,
    getProductsByCategoryId,
    getProductRendomByCategoryId,
  } = props;

  return (
    <>
      <div className="card-header">
        <h4 className="card-title">
          Practice Dialogue
          {/* <span className="float-end">
            <FormControlLabel
              control={
                <Switch
                  checked={randomProduct}
                  onChange={() => setRandomProduct(!randomProduct)}
                  name="loading"
                  color="primary"
                />
              }
              labelPlacement={`start`}
              label="Random Dialogue"
            />
          </span> */}
        </h4>
      </div>
      <div className="card-body">
        <div className="row">
          {allCategory.length > 0 && (
            <div className="form-group">
              <h6 className="card-title mb-3">Choose Your Difficulty Level</h6>
              <div className="row">
                {allCategory?.map((newVal) => (
                  <div className="level-list col-sm-4" key={newVal?.value}>
                    <Button
                      variant="outline-primary"
                      size="lg"
                      className={
                        category === newVal?.value ? `w-100 active` : `w-100`
                      }
                      onClick={() => {
                        setInitialValues({
                          ...initialValues,
                          category: newVal?.value,
                        });
                        if (randomProduct) {
                          getProductRendomByCategoryId(newVal?.value);
                        } else {
                          getProductsByCategoryId(newVal?.value);
                        }
                      }}
                    >
                      {newVal?.label}
                    </Button>
                  </div>
                ))}
              </div>
              <p className="mt-2">
                <b>Instructions:</b> Select the difficulty level that matches
                your skill level. Dialogues will be randomly generated based on
                the chosen difficulty. You will not receive any dialogues that
                you have previously practiced.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectCategory;
