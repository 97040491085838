import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ErrorToast, SuccessToast } from "../../../Utils/SweetAlert";
import {
  ExerciseResultDeclareService,
  GetExerciseByIdService,
} from "../../../Services/Api/exercise";
import SectionView from "./SectionView.jsx";
import Options from "./Options.jsx";

const ExerciseView = () => {
  const stateData = useLocation();
  const { id } = stateData?.state || "";

  const navigate = useNavigate();
  const [exerciseNote, setExerciseNote] = useState("");
  const [exerciseData, setExerciseData] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [sections, setSections] = useState({});
  const [originalAudioUrls, setOriginalAudioUrls] = useState([]);
  const [userAudioUrls, setUserAudioUrls] = useState([]);
  const [finishExerciseBtn, setFinishExerciseBtn] = useState(false);
  const [sectionCurrentCount, setSectionCurrentCount] = useState(1);
  const [prepareSections, setPrepareSections] = useState({});

  const [urlIndex, setUrlIndex] = useState(0);
  const sectionTotalCount = sections?.length;

  const getExerciseByIdService = async (id) => {
    await GetExerciseByIdService(id)
      .then((res) => {
        setExerciseData(res?.data?.data);
        // console.log("data = ", res?.data?.data);
        setInitialValues({
          language_id: res?.data?.data?.language_id,
          category_id: res?.data?.data?.category_id,
          product_id: res?.data?.data?.product_id,
          user_id: res?.data?.data?.user_id,
        });
        setExerciseNote(res?.data?.data?.note);
        setSections(res?.data?.data?.sections);
        // console.log("sections = ", res?.data?.data?.sections);
        const localAudio = res?.data?.data?.sections?.map(
          (section) => section.admin_file_url
        );
        setOriginalAudioUrls(localAudio);
        // console.log("localAudio = ", localAudio);
        const localUserAudio = res?.data?.data?.sections?.map(
          (section) => section.user_file_url
        );
        // console.log("localUserAudio = ", localUserAudio);
        setUserAudioUrls(localUserAudio);

        const localSectionIds = res?.data?.data?.sections?.map((section) => ({
          _id: section._id,
          mark: section?.marks || "",
          note: section?.note || "",
        }));
        setPrepareSections(localSectionIds);
      })
      .catch((err) => {
        if (typeof err.response.data.message !== "undefined") {
          ErrorToast(err.response.data.message || "Server Error!!");
        } else {
          ErrorToast(err?.message || "Server Error!!");
        }
      });
  };

  const onUrlChange = useCallback(
    (currentCount, btnType) => {
      let localCount = 0;
      if (btnType === 1) {
        localCount = currentCount + 1;
        setUrlIndex(currentCount + 1 - 1);
        setSectionCurrentCount(currentCount + 1);
      } else {
        localCount = currentCount - 1;
        setUrlIndex(currentCount - 1 - 1);
        setSectionCurrentCount(currentCount - 1);
      }
      // console.log("btnType, currentCount = ", btnType, currentCount);
      if (localCount >= sectionTotalCount) {
        setFinishExerciseBtn(true);
      } else {
        setFinishExerciseBtn(false);
      }
    },
    [sectionTotalCount]
  );

  const handleChangeValue = (field, value, localCount) => {
    const existingIndex = prepareSections?.findIndex(
      (item) => item._id === sections[localCount]._id
    );
    if (existingIndex !== -1) {
      setPrepareSections((prevLocalArray) => {
        const updatedArray = [...prevLocalArray];
        updatedArray[localCount] = {
          ...updatedArray[localCount],
          [field]: value,
        };
        return updatedArray;
      });
    }
  };

  useEffect(() => {
    getExerciseByIdService(id);
  }, [id]);

  return (
    <>
      <Helmet>
        <title>View Exercise | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h4 className="page-title">
                      Language: {exerciseData?.language?.name}
                    </h4>
                    <h4 className="page-title">
                      Category: {exerciseData?.category?.name}
                    </h4>
                    <h4 className="page-title">
                      Exercise Name: {exerciseData?.product?.name}
                    </h4>
                    <h4 className="page-title">
                      User Name: {exerciseData?.user?.name}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={async (values, { setSubmitting }) => {
                      const postData = {
                        exercise_id: exerciseData?._id,
                        note: exerciseNote,
                        sections: prepareSections,
                      };
                      await ExerciseResultDeclareService(postData)
                        .then((res) => {
                          SuccessToast(
                            res?.data?.message ||
                              "Exercise Updated successfully."
                          );
                          setSubmitting(false);
                          navigate("/admin/exercise");
                        })
                        .catch((err) => {
                          if (
                            typeof err.response.data.message !== "undefined"
                          ) {
                            ErrorToast(
                              err.response.data.message || "Server Error!!"
                            );
                          } else {
                            ErrorToast(err?.message || "Server Error!!");
                          }
                          setSubmitting(false);
                        });
                    }}
                  >
                    {(formik) => {
                      const { isSubmitting } = formik;
                      return (
                        <Form>
                          <div className="row">
                            {sections.length > 0 && (
                              <div className="main-sections">
                                <div className="form-group">
                                  <h3>
                                    Section {sectionCurrentCount} out of{" "}
                                    {sectionTotalCount}
                                  </h3>

                                  <SectionView
                                    urlIndex={urlIndex}
                                    originalAudioUrls={originalAudioUrls}
                                    userAudioUrls={userAudioUrls}
                                  />
                                </div>

                                <Options
                                  sections={sections}
                                  sectionCurrentCount={sectionCurrentCount}
                                />

                                {prepareSections?.map(
                                  (index, key) =>
                                    key === sectionCurrentCount - 1 && (
                                      <div key={key}>
                                        <div className="form-group">
                                          <label>Marks</label>
                                          <Field
                                            type="text"
                                            className={`form-control`}
                                            name={`mark_${sectionCurrentCount}`}
                                            placeholder="Enter Marks"
                                            value={index?.mark}
                                            onChange={(event) => {
                                              handleChangeValue(
                                                "mark",
                                                event.target.value,
                                                sectionCurrentCount - 1
                                              );
                                            }}
                                          />
                                          <ErrorMessage
                                            name={`mark_${sectionCurrentCount}`}
                                            component="span"
                                            className="error"
                                          />
                                        </div>
                                        <div className="form-group">
                                          <label>Notes</label>
                                          <Field
                                            as="textarea"
                                            className={`form-control`}
                                            name={`note_${sectionCurrentCount}`}
                                            placeholder="Enter any notes"
                                            value={index?.note}
                                            onChange={(event) => {
                                              handleChangeValue(
                                                "note",
                                                event.target.value,
                                                sectionCurrentCount - 1
                                              );
                                            }}
                                          />
                                          <ErrorMessage
                                            name={`note_${sectionCurrentCount}`}
                                            component="span"
                                            className="error"
                                          />
                                        </div>
                                      </div>
                                    )
                                )}

                                {finishExerciseBtn && (
                                  <div className="form-group">
                                    <h3>Exercise General Note</h3>
                                    <Field
                                      as="textarea"
                                      className={`form-control`}
                                      name={`exercise_note`}
                                      placeholder="Enter any notes"
                                      value={exerciseNote}
                                      onChange={(event) =>
                                        setExerciseNote(event.target.value)
                                      }
                                    />
                                    <ErrorMessage
                                      name={`exercise_note`}
                                      component="span"
                                      className="error"
                                    />
                                  </div>
                                )}

                                <div className="mt-4">
                                  {sectionCurrentCount > 1 && (
                                    <span className="d-flex justify-content-start">
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() =>
                                          onUrlChange(sectionCurrentCount, 2)
                                        }
                                      >
                                        Previous Section
                                      </button>
                                    </span>
                                  )}
                                  {sectionCurrentCount < sectionTotalCount && (
                                    <span className="d-flex justify-content-end">
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() =>
                                          onUrlChange(sectionCurrentCount, 1)
                                        }
                                      >
                                        Next Section
                                      </button>
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>

                          {finishExerciseBtn && (
                            <div className="text-end mt-4">
                              <button
                                type="submit"
                                className="btn btn-primary btnstl"
                                disabled={isSubmitting}
                              >
                                Submit
                                {isSubmitting && (
                                  <i className="fas fa-spinner fa-spin"></i>
                                )}
                              </button>
                            </div>
                          )}
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExerciseView;
