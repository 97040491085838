import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {
  useEffect(() => {}, []);

  return (
    <div className="header-area header-sticky">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/">
            <img src="/inner-assets/img/logo.png" alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <ul className="nav">
                <li className="nav-items">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-items">
                  <Link className="nav-link" to="/faq">
                    FAQ
                  </Link>
                </li>
                <li className="nav-items">
                  <Link className="nav-link" to="/contact">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-items">
                  <Link
                    className="nav-link btn btn-secondary"
                    to="/user/auth/login"
                  >
                    Login
                  </Link>
                </li>
                <li className="nav-items">
                  <Link className="nav-link btn btn-primary" to="/register">
                    Register Now!
                  </Link>
                </li>
              </ul>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
