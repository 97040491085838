import Api from "../../Utils/Axios";
import UserApi from "../../Utils/UserAxios";

/************************ ADMIN API *****************************/
export const GetExercisePaginateService = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await Api.get(`/exercise/paginate?per_page=${limit}&page=${page}`)
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const GetExerciseByIdService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/exercise/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

export const ExerciseResultDeclareService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/exercise/result-declare", data));
    } catch (error) {
      reject(error);
    }
  });
};

/************************ USER API *****************************/
export const CheckExerciseService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get("/exercise/check"));
    } catch (error) {
      reject(error);
    }
  });
};

export const AddExerciseService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/exercise/attermpt", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetAllExerciseService = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.get(`/exercise/list`));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetExercisePaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await UserApi.get(`/exercise/paginate?per_page=${limit}&page=${page}`)
      );
    } catch (error) {
      reject(error);
    }
  });
};
