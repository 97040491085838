import React from "react";
import "./NotSupported.scss";
import { Helmet } from "react-helmet";

const NotSupported = () => {
  return (
    <>
      <Helmet>
        <title>404 | PhrasePulse</title>
      </Helmet>
      <div
        id="browser-support-wrapper"
        style={{
          background: `linear-gradient(to bottom, "#FFFFFF", #FFFFFF)`,
        }}
      >
        <div className="banner">
          <img src="/inner-assets/img/logo.png" alt="Logo" />
          <span>404 Page Not Found</span>
        </div>
        <div className="not-supported-container">
          <h3 className="page-title-text">404 Page Not Found</h3>
          <p>
            If you use a supported browser other than, check its support page
            for instructions on how to turn on JavaScript. If you are not sure
            whether your browser supports JavaScript, check its support page.
          </p>
          <div className="browser-options">
            <div className="option">
              <img src="/inner-assets/img/logo.png" alt={`Google Chrome`} />
              <span>{`Google Chrome`}</span>
            </div>
            <div className="option">
              <img src="/inner-assets/img/logo.png" alt={`Mozilla Firefox`} />
              <span>{`Mozilla Firefox`}</span>
            </div>
            <div className="option">
              <img src="/inner-assets/img/logo.png" alt={`Opera`} />
              <span>{`Opera`}</span>
            </div>
            <div className="option">
              <img src="/inner-assets/img/logo.png" alt={`Microsoft Edge`} />
              <span>{`Microsoft Edge`}</span>
            </div>
            <div className="option">
              <img src="/inner-assets/img/logo.png" alt={`Safari`} />
              <span>{`Safari`}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NotSupported;
