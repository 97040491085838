import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import moment from "moment";
import { GetExercisePaginate } from "../../../Services/Api/exercise";
import PaginationSimple from "../../../Utils/PaginationSimple";
import { Loader } from "../../../Utils/Loader";
import { UserFooter } from "../Layout";

const ExerciseList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [exerciseListPagination, setexerciseListPagination] = useState([]);
  const limit = 12;

  const GetExerciseList = async (limit = "", page = "") => {
    setIsLoading(true);
    await GetExercisePaginate({ limit: limit, page: page })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setexerciseListPagination(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setexerciseListPagination([]);
          setTotalRecords(0);
          // ErrorToast("No Results.");
        }
      })
      .catch((err) => {
        setexerciseListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  useEffect(() => {
    GetExerciseList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>Exercise | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Exercises</h3>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <span onClick={() => navigate("/user/dashboard")}>
                      Dashboard
                    </span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Exercises</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>

          <div className="card card-table">
            <div className="card-header">
              <h4 className="card-title">Exercise List</h4>
            </div>
            <div className="card-body p-2 mt-2">
              <Row>
                {!isLoading && (
                  <>
                    {exerciseListPagination.map((exercise, index) => (
                      <Col sm={4} md={4} lg={4} xs={12} key={index}>
                        <div className="card p-3 bg-white-light">
                          <div className="card-body">
                            <h5 className="card-title">
                              {exercise?.product?.name || ""}
                              <span className="float-end badge bg-primary fw-normal">
                                {exercise?.credit_used || 0} PPC
                              </span>
                            </h5>
                            <div className="d-flex flex-column pb-3 border-bottom">
                              <div className="d-flex justify-content-between">
                                <div>Language: </div>
                                <div className="text-start">
                                  {exercise?.language?.name || ""}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <div>Category: </div>
                                <div>{exercise?.category?.name || ""}</div>
                              </div>
                              <div className="d-flex flex-column text-end">
                                <div className="text-secondary">
                                  Attempt Date:{" "}
                                </div>
                                <div>
                                  {moment(exercise?.createdOn).format(
                                    "YYYY-MM-DD hh:mm A"
                                  ) || ""}{" "}
                                </div>
                              </div>
                            </div>
                            <div className="text-center m-2">
                              {exercise?.marks || "Yet not declare"}
                              {exercise?.note || ""}
                            </div>
                            <div className="text-center mt-3">
                              {exercise?.status === 2 ? (
                                <span className="btn btn-success btn-sm">
                                  Completed
                                </span>
                              ) : exercise?.status === 3 ? (
                                <span className="btn btn-danger btn-sm">
                                  Cancceled
                                </span>
                              ) : (
                                <Link
                                  to="/user/start-exercise"
                                  className="btn btn-primary"
                                  title="Click to Continue"
                                >
                                  Running
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </>
                )}
                {isLoading && <Loader loading={isLoading} />}
                <div className="d-flex justify-content-center mb-2">
                  <PaginationSimple
                    totalItem={totalRecords}
                    itemsPerPage={limit}
                    page={page}
                    handleChangePage={handlePageChange}
                  />
                </div>
              </Row>
            </div>
          </div>
        </div>
        <UserFooter />
      </div>
    </>
  );
};

export default ExerciseList;
