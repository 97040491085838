import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { UserRegisterService } from "../../Services/Api/auth";
import { SuccessToast, ErrorToast } from "../../Utils/SweetAlert";
import Information from "./Information";
import "react-phone-number-input/style.css";

const UserRegister = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [email, setEmail] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [referralCode, setReferralCode] = useState("");

  const registerSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .max(50, "Email can contain a maximum of 40 characters")
      .required("Email is required."),
    name: Yup.string()
      .required("Name is required.")
      .min(4, "Name must have a minimum of 4 characters")
      .max(40, "Name can contain a maximum of 40 characters")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
    referral: Yup.string()
      .min(6, "Code must have a minimum of 6 characters")
      .max(6, "Code can contain a maximum of 6 characters")
      .matches(/^[a-zA-Z0-9\s]+$/, "Only alphabets/numbers are allowed"),
    password: Yup.string()
      .required("Password is required.")
      .max(40, "Password can contain a maximum of 40 characters")
      .test(
        "uppderCase",
        "Must contain at least one uppercase character",
        (value) => /[A-Z]/.test(value)
      )
      .test(
        "lowerCase",
        "Must contain at least one lowercase character",
        (value) => /[a-z]/.test(value)
      )
      .test("oneNumber", "Must contain at least one number", (value) =>
        /[0-9]/.test(value)
      )
      .test(
        "oneSpecial",
        "Must contain at least one special character",
        (value) => /[!@#%&]/.test(value)
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const initialValues = {
    email: "",
    name: "",
    password: "",
    confirm_password: "",
    referral: "",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const utmReferral = params.get("utm_referral");
    setReferralCode(utmReferral);
  }, []);

  return !showConfirm ? (
    <>
      <Helmet>
        <title>User Register | PhrasePulse</title>
      </Helmet>
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            <Link to={`/`}>
              <img
                className="img-fluid logo-dark mb-2"
                src="/inner-assets/img/logo.png"
                alt="Logo"
              />
            </Link>
            <div className="row loginbox">
              <div className="login-right">
                <div className="login-right-wrap">
                  <h1 className="title-text mb-4">Register</h1>
                  <p className="text-center mb-3">
                    Please Register to your Account
                  </p>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={registerSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      const postDate = {
                        name: values.name,
                        email: values.email,
                        password: values.password,
                        referal_code: values.referral
                          ? values?.referral
                          : referralCode,
                      };
                      setEmail(values.email);
                      if (values.check) {
                        await UserRegisterService(postDate)
                          .then((res) => {
                            SuccessToast(
                              res?.data?.message || "Register successfully."
                            );
                            setSubmitting(false);
                            setShowConfirm(true);
                          })
                          .catch((err) => {
                            if (
                              typeof err.response.data.message !== "undefined"
                            ) {
                              ErrorToast(
                                err.response.data.message || "Server Error!!"
                              );
                            } else {
                              ErrorToast(err?.message || "Server Error!!");
                            }
                          });
                      } else {
                        setShow(true);
                      }
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleSubmit,
                      setFieldValue,
                      isSubmitting,
                      values,
                    }) => {
                      return (
                        <Form onSubmit={handleSubmit} id="register-form">
                          <div className="col-xs-12 col-sm-12 col-md-12">
                            <div className="form-group">
                              <label
                                htmlFor="reg-name"
                                className="form-contol-label"
                              >
                                Full Name*
                              </label>
                              <Field
                                type="text"
                                className={`form-control ${
                                  errors.name && touched.name
                                    ? "input-error"
                                    : null
                                }`}
                                name="name"
                                placeholder="Enter name"
                              />
                              <ErrorMessage
                                name="name"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group">
                              <label
                                htmlFor="reg-email"
                                className="form-contol-label"
                              >
                                Email*
                              </label>
                              <Field
                                type="email"
                                className={`form-control ${
                                  errors.email && touched.email
                                    ? "input-error"
                                    : null
                                }`}
                                name="email"
                                placeholder="Enter email"
                              />
                              <ErrorMessage
                                name="email"
                                component="span"
                                className="error"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group col-xs-6 col-sm-6 col-md-6">
                              <label
                                htmlFor="reg-password"
                                className="form-contol-label"
                              >
                                Password*
                              </label>
                              <div className="pass-group">
                                <Field
                                  type={isShowPassword ? "text" : "password"}
                                  className={`form-control ${
                                    errors.password && touched.password
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="password"
                                  placeholder="Enter password"
                                />
                                <span
                                  onClick={() =>
                                    setIsShowPassword(!isShowPassword)
                                  }
                                  className={`fas ${
                                    isShowPassword ? "fa-eye-slash" : "fa-eye"
                                  } toggle-password`}
                                />
                              </div>
                              <ErrorMessage
                                name="password"
                                component="span"
                                className="error"
                              />
                            </div>
                            <div className="form-group col-xs-6 col-sm-6 col-md-6">
                              <label>
                                Confirm Password{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="inputWrap">
                                <Field
                                  type={
                                    isShowConfirmPassword ? "text" : "password"
                                  }
                                  className={`form-control ${
                                    errors.confirm_password &&
                                    touched.confirm_password
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="confirm_password"
                                  placeholder="Confirm password"
                                />
                                <span
                                  onClick={() =>
                                    setIsShowConfirmPassword(
                                      !isShowConfirmPassword
                                    )
                                  }
                                  className={`fas ${
                                    isShowConfirmPassword
                                      ? "fa-eye-slash"
                                      : "fa-eye"
                                  } toggle-password-user`}
                                />
                              </div>
                              <ErrorMessage
                                name="confirm_password"
                                component="span"
                                className="error"
                              />
                            </div>
                          </div>
                          {referralCode && (
                            <div className="row">
                              <div className="form-group col-xs-6 col-sm-6 col-md-6">
                                <label
                                  htmlFor="reg-referral"
                                  className="form-contol-label"
                                >
                                  Referral Code
                                </label>
                                <Field
                                  type="text"
                                  className={`form-control ${
                                    errors.referral && touched.referral
                                      ? "input-error"
                                      : null
                                  }`}
                                  name="referral"
                                  placeholder="Enter referral code"
                                  value={
                                    values?.referral
                                      ? values.referral
                                      : referralCode || ""
                                  }
                                />
                                <ErrorMessage
                                  name="referral"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          )}
                          <div className="form-group">
                            <div className="row">
                              <div className="custom-control custom-checkbox">
                                <label className="custom_check" htmlFor="cb1">
                                  <input
                                    type="checkbox"
                                    name="check"
                                    id="cb1"
                                    onChange={(e) => {
                                      setFieldValue("check", e.target.checked);
                                      setShow(false);
                                    }}
                                  />{" "}
                                  I Agree with the{" "}
                                  <Link
                                    to={`/term-and-condition`}
                                    target="_black"
                                  >
                                    Terms And Conditions
                                  </Link>
                                  <span className="checkmark" />
                                  <br />
                                  {show && (
                                    <span className="text-danger">
                                      <strong>
                                        Please accept terms and conditions.
                                      </strong>
                                    </span>
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-lg btn-block btn-primary w-100"
                          >
                            Register Now&nbsp;&nbsp;
                            {isSubmitting && (
                              <i className="fas fa-spinner fa-spin"></i>
                            )}
                          </button>
                          <div className="text-center dont-have register-link-client">
                            Already have an account ?{" "}
                            <Link to="/user/auth/login">Login</Link>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Information
      title={`Confirm Email`}
      caption={`Confirm Email`}
      decription={`Before You go to Login Please check your [${email}] email account to confirm your identity.`}
      short_desc={`Already have an accept email?`}
      link_text={`Login Now`}
      link={`/user/auth/login`}
    />
  );
};

export default UserRegister;
