import Api from "../../Utils/Axios";
import UserApi from "../../Utils/UserAxios";

/***************** Admin API **********************/

export const GetSupportPaginateService = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await Api.get(`/support/paginate?per_page=${limit}&page=${page}`)
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const ReplySupportService = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.post("/support/replay", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const GetSupportByIdService = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await Api.get(`/support/${id}`));
    } catch (error) {
      reject(error);
    }
  });
};

/***************** User API **********************/

export const GetSupportPaginate = async ({ limit, page }) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(
        await UserApi.get(`/support/paginate?per_page=${limit}&page=${page}`)
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const AddSupport = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await UserApi.post("/support/store", data));
    } catch (error) {
      reject(error);
    }
  });
};
