import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Pagination } from "../../../../Utils";
import {
  GetClientUserPaginate,
  ActiveInactiveClientUser,
} from "../../../../Services/Api/user";
import { AdminFooter } from "../../../Layout";
import moment from "moment";
import {
  ErrorToast,
  Confirmation,
  SuccessToast,
} from "../../../../Utils/SweetAlert";

const ClientUser = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userListPagination, setuserListPagination] = useState([]);

  const GetUserList = async (limitO = "", pageO = "") => {
    setIsLoading(true);
    await GetClientUserPaginate({ limit: limitO, page: pageO })
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.data?.data?.length) {
          setuserListPagination(res?.data?.data?.data);
          setTotalRecords(res?.data?.data?.paginate?.total);
        } else {
          setuserListPagination([]);
          setTotalRecords(0);
        }
      })
      .catch((err) => {
        setuserListPagination([]);
        setTotalRecords(0);
        setIsLoading(false);
      });
  };

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const changeActive = (user, text) => {
    Confirmation(text).then(async (result) => {
      if (result.isConfirmed) {
        await ActiveInactiveClientUser(user?._id)
          .then((res) => {
            SuccessToast(res?.data.message || "Status Updated");
            GetUserList(limit, page);
          })
          .catch((err) => {
            if (typeof err.response.data.message !== "undefined") {
              ErrorToast(err.response.data.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };

  useEffect(() => {
    GetUserList(limit, page);
  }, [limit, page]);

  return (
    <>
      <Helmet>
        <title>Client Users | PhrasePulse</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Client Users</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>Name</th>
                          <th>Created</th>
                          <th>Email</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading && (
                          <>
                            {userListPagination.map((user, index) => (
                              <tr key={index}>
                                <td>
                                  <h2 className="table-avatar">
                                    {user?.name || ""}{" "}
                                    <span>{user?.mobile || ""}</span>
                                  </h2>
                                </td>
                                <td>
                                  {moment(user?.createdOn).format(
                                    "DD-MM-YYYY hh:mm A"
                                  ) || ""}
                                </td>
                                <td>{user?.email || "-"}</td>
                                <td>
                                  {user?.status === false && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-danger"
                                      onClick={() => {
                                        changeActive(
                                          user,
                                          "Want to active user?"
                                        );
                                      }}
                                    >
                                      Inactive
                                    </button>
                                  )}
                                  {user?.status === true && (
                                    <button
                                      className="btn btn-sm  btn-block btn-outline-success"
                                      onClick={() => {
                                        changeActive(
                                          user,
                                          "Want to deactivate user?"
                                        );
                                      }}
                                    >
                                      Active
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                            {userListPagination.length === 0 && (
                              <tr>
                                <td colSpan={4}>No Records Found.</td>
                              </tr>
                            )}
                          </>
                        )}
                        {isLoading && (
                          <tr>
                            <td align="center" colSpan={4}>
                              Loading...
                            </td>
                          </tr>
                        )}
                        <Pagination
                          totalItem={totalRecords}
                          itemsPerPage={limit}
                          page={page}
                          handleChangePage={handlePageChange}
                          handleChangeRowsPerPage={handleLimitChange}
                          colSpan={4}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientUser;
